import React from 'react';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
/*import reportWebVitals from './reportWebVitals';*/
import { store } from './store';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
