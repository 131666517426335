export const Breakpoints = {
  BIG_BREAKPOINT: 2000,
  MEDIUM_BREAKPOINT: 600,
  MOBILE_BREAKPOINT: 767,
  MOBILE_BREAKPOINT_MINI: 370,
};

export const isScreenSmall = () => {
  return window.innerWidth <= Breakpoints.MOBILE_BREAKPOINT;
};

export const getMaxTestimonialsDisplayed = () => {
  if (isScreenSmall()) {
    return 3;
  } else {
    return 5;
  }
};
