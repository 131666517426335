import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import './locales/i18n';
import ThemeConfig from './constants/theme';
import GlobalStyles from './constants/theme/globalStyles';
import { GuestSwitch } from './routes/GuestSwitch';
import { BrowserRouter as Router } from 'react-router-dom';

const App: React.VFC = () => {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <I18nextProvider i18n={i18next}>
        <Router>
          <GuestSwitch />
        </Router>
      </I18nextProvider>
    </ThemeConfig>
  );
};

export default App;
