import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  getPublishedByWebsite,
  Testimonial,
  Trigger,
  updateTestimonial,
} from '../slices/testimonial';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import TestimonialCard from '../components/TestimonialCard/TestimonialCard';
import { createUseStyles } from 'react-jss';
import { testimonialsSelector } from '../store';

export const useStyles = createUseStyles({
  mainWrapper: {
    position: 'fixed',
    top: '6px',
    right: '8px',
    zIndex: '999',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily:
      "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
    '&.mobile': {
      position: 'fixed',
      textAlign: 'center',
      justifyContent: 'center',
      right: '0px',
      left: '0px',
      flexDirection: 'row',
      width: '100%',
      '&.side': {
        flexDirection: 'column',
      },
    },
  },
  '@keyframes popIn': {
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
  },
  navButton: {
    animation: `$popIn .3s both 1.4s`,
    width: '40px',
    height: '40px',
    margin: '3px',
    padding: '3px',
    background: 'rgba(255, 255, 255, 0.3)',
    transform: 'scale(1)',
    transition: '0.15s',
    alignItems: 'center !important',
    '& svg': {
      transform: 'rotate(90deg)',
      width: '75%',
      right: '40px',
    },
    '&:disabled': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'rgba(0, 0, 0, 0.2)',
    },
    '&.mobile': {
      margin: '0px',
      padding: '0px',
      background: 'transparent',
      transition: '0.15s',
      '&.side': {
        '& svg': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        transform: 'rotate(0deg)',
      },
    },
  },
  list: {
    bottom: '80%',
    listStyle: 'none',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    button: {
      padding: 0,
      margin: 0,
      borderRadius: 0,
    },
    '&.mobile': {
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '10px',
      bottom: '0%',
      '&.side': {
        gap: '8px',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  slide: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blocHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    transform: 'rotate(90deg) translateY(-100vh)',
    transformOrigin: 'top left',
  },
  container: {
    width: '100vh',
    height: '100vw',
    transform: 'rotate(-90deg) translateX(-100vh)',
    transformOrigin: 'top left',
    overflowX: 'hidden',
  },
});

const TestimonialsElements: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [currentTestimonialId, setCurrentTestimonialId] = useState('');
  const [displayedTestimonials, setDisplayedTestimonials] = useState<
    Testimonial[]
  >([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateIn, setAnimateIn] = useState(true);

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  const {
    mainColor,
    secondaryColor,
    enableOnMobile,
    tags,
    position,
    zIndex,
    trigger,
    isEqual,
    testimonialBubbleRadius,
    testimonialBubbleDimension,
    outlineBubble,
    userId,
    hideKudeoButton,
  } = useAppSelector(
    ({
      testimonials: {
        mainColor,
        secondaryColor,
        enableOnMobile,
        tags,
        position,
        zIndex,
        trigger,
        isEqual,
        testimonialBubbleRadius,
        testimonialBubbleDimension,
        outlineBubble,
        userId,
        hideKudeoButton,
      },
    }) => ({
      mainColor,
      secondaryColor,
      enableOnMobile,
      tags,
      position,
      zIndex,
      trigger,
      isEqual,
      testimonialBubbleRadius,
      testimonialBubbleDimension,
      outlineBubble,
      userId,
      hideKudeoButton,
    }),
  );

  const [triggerItem, setTriggerItem] = useState<Trigger>({
    openTestimonialOnDesktopOnLoad: true,
    openTestimonialOnMobileOnLoad: false,
    timeBeforeOpenOnDesktop: 1500,
    timeBeforeOpenOnMobile: 0,
    openOnScroll: false,
    openOnScrollMobile: false,
    timeBeforeOpenOnMobileState: false,
    timeBeforeOpenOnDesktopState: false,
    openOnClick: false,
  });

  useEffect(() => {
    window.parent.postMessage(
      {
        name: 'getTestimonialBubbleSize',
        args: testimonialBubbleDimension,
      },
      '*',
    );
  }, [testimonialBubbleDimension]);

  useEffect(() => {
    if (trigger) {
      setTriggerItem(trigger);
    }
  }, [trigger]);

  useEffect(() => {
    window.parent.postMessage(
      { name: 'getPositionPlugin', args: position },
      '*',
    );
  }, [position]);

  useEffect(() => {
    window.parent.postMessage({ name: 'getZindex', args: zIndex }, '*');
  }, [zIndex]);

  const [isSideParamPosition, setIsSideParamPosition] = useState(false);

  useEffect(() => {
    if (position === 'left' || position === 'right') {
      setIsSideParamPosition(true);
    }
  }, [position]);

  useEffect(() => {
    window.parent.postMessage(
      {
        name: 'displayedTestimonials',
        args: {
          displayedLength: displayedTestimonials.length,
          hideKudeoButton: hideKudeoButton,
        },
      },
      '*',
    );
  }, [displayedTestimonials, hideKudeoButton]);

  const [
    getMaxTestimonialsDisplayedState,
    setGetMaxTestimonialsDisplayedState,
  ] = useState(0);

  const isScreenSmall = (width: any) => {
    return width <= 767;
  };

  useEffect(() => {
    if (tags) {
      if (tags.length === 1) {
        window.parent.postMessage({ name: 'getTag', args: tags[0] }, '*');
      } else if (tags.length === 2) {
        window.parent.postMessage({ name: 'getTag', args: tags[1] }, '*');
      }
    }
  }, [tags]);

  const onHoverIncrement = async (
    testimonial: Testimonial,
    isOpen: boolean,
    defaultOpen?: boolean,
  ) => {
    if (!isMobile && !defaultOpen) {
      if (sessionStorage.getItem('hovered=' + testimonial._id) === null) {
        sessionStorage.setItem('hovered=' + testimonial._id, '1');
      } else {
        sessionStorage.setItem(
          'hovered=' + testimonial._id,
          (
            parseInt(
              sessionStorage.getItem('hovered=' + testimonial._id) as string,
            ) + 1
          ).toString(),
        );
      }
    }

    if (isOpen) {
      if (sessionStorage.getItem('videoOpened=' + testimonial._id) === null) {
        sessionStorage.setItem('videoOpened=' + testimonial._id, '1');
      } else {
        sessionStorage.setItem(
          'videoOpened=' + testimonial._id,
          (
            parseInt(
              sessionStorage.getItem(
                'videoOpened=' + testimonial._id,
              ) as string,
            ) + 1
          ).toString(),
        );
      }
    }
  };

  const updateStatistiquesFunction = async () => {
    const listTags = tags.map((tag) => tag.tag);
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key?.includes('hovered=')) {
        if (userId) {
          await dispatch(
            updateTestimonial({
              testimonialId: key.substring(key.indexOf('=') + 1),
              whichStats: 'hover',
              associatedTag: listTags,
              device: isScreenSmall(dataWidth) ? 'mobile' : 'desktop',
              numberOfStats: parseInt(sessionStorage.getItem(key) ?? '0') ?? 0,
              userId: userId,
            }),
          );
        }
      } else if (key?.includes('click=')) {
        if (userId) {
          dispatch(
            await updateTestimonial({
              testimonialId: key.substring(key.indexOf('=') + 1),
              whichStats: 'click',
              associatedTag: listTags,
              device: isScreenSmall(dataWidth) ? 'mobile' : 'desktop',
              numberOfStats: parseInt(sessionStorage.getItem(key) ?? '0') ?? 0,
              userId: userId,
            }),
          );
        }
      } else if (key?.includes('videoOpened=')) {
        if (userId) {
          dispatch(
            await updateTestimonial({
              testimonialId: key.substring(key.indexOf('=') + 1),
              whichStats: 'videoPlayed',
              associatedTag: listTags,
              device: isScreenSmall(dataWidth) ? 'mobile' : 'desktop',
              numberOfStats: parseInt(sessionStorage.getItem(key) ?? '0') ?? 0,
              userId: userId,
            }),
          );
        }
      }
    }
    sessionStorage.clear();
  };

  async function handleBeforeUnload() {
    await updateStatistiquesFunction();
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await updateStatistiquesFunction();
    }, 30000);
    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  const [isMobile, setIsMobile] = useState(false);

  const getMaxTestimonialsDisplayed = (width: any) => {
    if (isScreenSmall(width)) {
      if (testimonials.length != 0) {
        if (testimonials.length > 4) {
          setGetMaxTestimonialsDisplayedState(3);
        } else {
          setGetMaxTestimonialsDisplayedState(4);
        }
      }
    } else if (!isScreenSmall(width)) {
      setGetMaxTestimonialsDisplayedState(4);
    }
  };

  const [testimonialsList, setTestimonialsList] = useState<Testimonial[]>([]);

  useEffect(() => {
    setTestimonialsList(
      testimonials
        .filter((testimonial) => {
          return tags.some((tag) => {
            if (
              !testimonial.tags.includes(tag.tag) ||
              !tag.displayLocation.showOnPlugin
            ) {
              return false;
            }

            const displayProperty = testimonial.displayProperties?.find(
              (temp) => temp.tagAssociated === tag.tag,
            );

            if (!displayProperty) {
              return true;
            }

            if (
              (displayProperty.onMobile && isScreenSmall(dataWidth)) ||
              (displayProperty.onDesktop && !isScreenSmall(dataWidth))
            ) {
              return true;
            }

            return false;
          });
        })
        .sort((a, b) => {
          if (!a.indexPosition || !b.indexPosition || tags.length === 0) {
            return 0;
          }

          for (const tag of tags) {
            const indexA =
              a.indexPosition.find((temp) => temp.tag === tag.tag)?.index || 0;
            const indexB =
              b.indexPosition.find((temp) => temp.tag === tag.tag)?.index || 0;

            if (indexA !== indexB) {
              return indexA - indexB;
            }
          }

          return 0;
        }),
    );
  }, [testimonials]);

  useEffect(() => {
    if (testimonialsList.length) {
      window.parent.postMessage(
        {
          name: 'resizeIframeElements',
          args: {
            testimonialsLength: testimonialsList.length,
            testimonialTitle: displayedTestimonials.filter((testimonial) => {
              return (
                testimonial.title != '' &&
                testimonial.titlePosition != 'inside' &&
                testimonial.title != '<p><br></p>' &&
                testimonial.title
              );
            }).length,
            hideKudeoButton: hideKudeoButton,
          },
        },
        '*',
      );
    }
  }, [testimonials, hideKudeoButton, displayedTestimonials]);

  // send testimonials color and index of the map to the shim
  const getTestimonials = (testimonial: any, index: number) => {
    window.parent.postMessage(
      {
        name: 'getTestimonial',
        args: {
          testimonial: testimonial,
          video: testimonial.testimonialType === 'video',
          vertical: testimonial.displayPreferences.showVertical,
          square: testimonial.displayPreferences.showSquare,
        },
      },
      '*',
    );

    window.parent.postMessage(
      {
        name: 'getColor',
        args: { mainColor: mainColor, secondaryColor: secondaryColor },
      },
      '*',
    );
    window.parent.postMessage(
      {
        name: 'getIndex',
        args: { index: index, displayNavigation: displayNavigation() },
      },
      '*',
    );
  };

  const sendOnClickNav = () => {
    window.parent.postMessage({ name: 'onClickNav', args: true }, '*');
    //Set isOpen = true if we click the button
    if (isScreenSmall(dataWidth)) {
      window.parent.postMessage({ name: 'getIsOpen', args: true }, '*');
    }
  };

  const [data, setData] = useState<{ name: string; args: any }>({
    name: '',
    args: '',
  });
  const [dataWidth, setDataWidth] = useState(0);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleMessage = (event: MessageEvent<{ name: string; args: any }>) => {
    setData(event?.data);
  };

  // event listener for messages from the shim
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  // get testimonials from the shim
  useEffect(() => {
    if (data.name == 'getWidth') {
      setDataWidth(data.args);
    }
    if (data.name == 'setAnimate') {
      setAnimateIn(data.args);
    }
    if (data.name == 'getIsCloseModal') {
      setCurrentTestimonialId('');
      setData({
        name: '',
        args: '',
      });
    }
    if (data.name == 'scrolled') {
      setIsScrolled(data.args.scrolled);
    }
  }, [data, testimonialsList]);

  useEffect(() => {
    if (testimonialsList.length > 0) {
      if (isScrolled && triggerItem.openOnScroll) {
        setCurrentTestimonialId(testimonialsList[0]._id);
        window.parent.postMessage(
          {
            name: 'setFirstTestimonials',
            args: {
              testimonial: testimonialsList[0],
              displayNavigation: testimonialsList.length >= 5,
              video: testimonialsList[0].testimonialType === 'video',
              vertical: testimonialsList[0].displayPreferences.showVertical,
              square: testimonialsList[0].displayPreferences.showSquare,
            },
          },
          '*',
        );
        onHoverIncrement(testimonialsList[0], true, true);
        window.parent.postMessage(
          {
            name: 'getColor',
            args: { mainColor: mainColor, secondaryColor: secondaryColor },
          },
          '*',
        );
        setIsScrolled(false);
      }
      if (
        isScrolled &&
        triggerItem.openOnScrollMobile &&
        isScreenSmall(dataWidth)
      ) {
        setCurrentTestimonialId(testimonialsList[0]._id);
        window.parent.postMessage(
          {
            name: 'setFirstTestimonials',
            args: {
              testimonial: testimonialsList[0],
              displayNavigation: testimonialsList.length >= 5,
              video: testimonialsList[0].testimonialType === 'video',
              vertical: testimonialsList[0].displayPreferences.showVertical,
              square: testimonialsList[0].displayPreferences.showSquare,
            },
          },
          '*',
        );
        onHoverIncrement(testimonialsList[0], true, true);
        window.parent.postMessage(
          {
            name: 'getColor',
            args: { mainColor: mainColor, secondaryColor: secondaryColor },
          },
          '*',
        );
        setIsScrolled(false);
      }
    }
  }, [isScrolled]);

  const [timeOutRef, setTimeOutRef] = useState<any>(null);

  useEffect(() => {
    if (data.name == 'setUrlOrigin') {
      dispatch(
        getPublishedByWebsite({
          origin: data.args.origin.split('?')[0],
          href: data.args.href.split('?')[0],
          hostname: data.args.hostname,
          params: data.args.params,
          hash: data.args.hash,
          device: data.args.device,
        }),
      );

      if (timeOutRef) {
        clearTimeout(timeOutRef);
        setTimeOutRef(null);
      }
    }
  }, [data]);

  useEffect(() => {
    getMaxTestimonialsDisplayed(dataWidth);
  }, [dataWidth, testimonials]);

  useEffect(() => {
    setIsMobile(isScreenSmall(dataWidth));
    if (!enableOnMobile && isScreenSmall(dataWidth)) {
      return;
    }

    setDisplayedTestimonials(
      testimonialsList.slice(0, getMaxTestimonialsDisplayedState),
    );
    if (testimonialsList.length > 0 && !isEqual) {
      setCurrentTestimonialId('');
      //if we are not in a terminal mobile
      if (
        !isScreenSmall(dataWidth) &&
        triggerItem.timeBeforeOpenOnDesktopState === true
      ) {
        setTimeOutRef(
          setTimeout(() => {
            setCurrentTestimonialId(testimonialsList[0]._id);
            window.parent.postMessage(
              {
                name: 'setFirstTestimonials',
                args: {
                  testimonial: testimonialsList[0],
                  displayNavigation: testimonialsList.length >= 5,
                  video: testimonialsList[0].testimonialType === 'video',
                  vertical: testimonialsList[0].displayPreferences.showVertical,
                  square: testimonialsList[0].displayPreferences.showSquare,
                },
              },
              '*',
            );
            onHoverIncrement(testimonialsList[0], true, true);
            window.parent.postMessage(
              {
                name: 'getColor',
                args: {
                  mainColor: mainColor,
                  secondaryColor: secondaryColor,
                },
              },
              '*',
            );
          }, triggerItem.timeBeforeOpenOnDesktop * 1000),
        );
      } else if (
        isScreenSmall(dataWidth) &&
        triggerItem.timeBeforeOpenOnMobileState === true
      ) {
        setTimeout(() => {
          setCurrentTestimonialId(testimonialsList[0]._id);
          window.parent.postMessage(
            {
              name: 'setFirstTestimonials',
              args: {
                testimonial: testimonialsList[0],
                displayNavigation: testimonialsList.length >= 5,
                video: testimonialsList[0].testimonialType === 'video',
                vertical: testimonialsList[0].displayPreferences.showVertical,
                square: testimonialsList[0].displayPreferences.showSquare,
              },
            },
            '*',
          );
          onHoverIncrement(testimonialsList[0], true, true);

          window.parent.postMessage(
            {
              name: 'getColor',
              args: { mainColor: mainColor, secondaryColor: secondaryColor },
            },
            '*',
          );
        }, triggerItem.timeBeforeOpenOnMobile * 1000);
      } else if (
        !isScreenSmall(dataWidth) &&
        triggerItem.openTestimonialOnDesktopOnLoad === true
      ) {
        setTimeout(() => {
          setCurrentTestimonialId(testimonialsList[0]._id);
          window.parent.postMessage(
            {
              name: 'setFirstTestimonials',
              args: {
                testimonial: testimonialsList[0],
                displayNavigation: testimonialsList.length >= 5,
                video: testimonialsList[0].testimonialType === 'video',
                vertical: testimonialsList[0].displayPreferences.showVertical,
                square: testimonialsList[0].displayPreferences.showSquare,
              },
            },
            '*',
          );
          onHoverIncrement(testimonialsList[0], true, true);
          window.parent.postMessage(
            {
              name: 'getColor',
              args: { mainColor: mainColor, secondaryColor: secondaryColor },
            },
            '*',
          );
        }, 1500);
      } else if (
        isScreenSmall(dataWidth) &&
        triggerItem.openTestimonialOnMobileOnLoad === true
      ) {
        setTimeout(() => {
          setCurrentTestimonialId(testimonialsList[0]._id);
          window.parent.postMessage(
            {
              name: 'setFirstTestimonials',
              args: {
                testimonial: testimonialsList[0],
                displayNavigation: testimonialsList.length >= 5,
                video: testimonialsList[0].testimonialType === 'video',
                vertical: testimonialsList[0].displayPreferences.showVertical,
                square: testimonialsList[0].displayPreferences.showSquare,
              },
            },
            '*',
          );
          onHoverIncrement(testimonialsList[0], true, true);
          window.parent.postMessage(
            {
              name: 'getColor',
              args: { mainColor: mainColor, secondaryColor: secondaryColor },
            },
            '*',
          );
        }, 1500);
      }
    }
  }, [testimonialsList, dataWidth]);

  const setIsOpen = (dimmed: boolean, isOpen: boolean) => {
    window.parent.postMessage(
      {
        name: 'getIsOpen',
        args: { isOpen, dimmed },
      },
      '*',
    );
  };

  const onClickPrevious = () => {
    setCurrentTestimonialId('');
    setAnimateIn(true);
    const maxIndex = testimonialsList.length - 1;
    const testimonialsPerPage = getMaxTestimonialsDisplayedState;

    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = maxIndex;
    }

    const startIndex = newIndex - testimonialsPerPage + 1;
    const sliced =
      startIndex >= 0
        ? testimonialsList.slice(startIndex, startIndex + testimonialsPerPage)
        : testimonialsList
            .slice(maxIndex + startIndex + 1)
            .concat(testimonialsList.slice(0, newIndex + 1));

    setDisplayedTestimonials(sliced);
    setCurrentIndex(newIndex);

    sendOnClickNav();
  };

  const onClickNext = () => {
    setCurrentTestimonialId('');
    const newIndex = currentIndex + 1;
    const sliced = testimonialsList.slice(
      newIndex,
      newIndex + getMaxTestimonialsDisplayedState,
    );

    if (newIndex + getMaxTestimonialsDisplayedState > testimonialsList.length) {
      if (newIndex < testimonialsList.length) {
        setDisplayedTestimonials(
          sliced.concat(
            testimonialsList.slice(
              0,
              -testimonialsList.length +
                newIndex +
                getMaxTestimonialsDisplayedState,
            ),
          ),
        );
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
        setDisplayedTestimonials(
          testimonialsList.slice(0, getMaxTestimonialsDisplayedState),
        );
      }
    } else {
      setDisplayedTestimonials(sliced);
      setCurrentIndex(currentIndex + 1);
    }
    sendOnClickNav();
  };

  const displayNavigation = () => {
    return testimonialsList.length !== displayedTestimonials.length;
  };

  useEffect(() => {
    window.parent.postMessage(
      { name: 'displayNavigation', args: displayNavigation() },
      '*',
    );
  }, [displayNavigation()]);

  return displayedTestimonials.length ? (
    <>
      <div className={`${isMobile ? 'container' : ''}`}>
        <div
          className={`${classes.mainWrapper} ${isMobile ? 'mobile' : ''} ${
            isSideParamPosition ? 'side' : ''
          }`}
        >
          {displayNavigation() && (
            <IconButton
              className={`${classes.navButton} ${isMobile ? 'mobile' : ''} ${
                isSideParamPosition ? 'side' : ''
              }`}
              style={
                displayedTestimonials.filter((testimonial) => {
                  return (
                    testimonial.title != '' &&
                    testimonial.titlePosition != 'inside' &&
                    testimonial.title != '<p><br></p>' &&
                    testimonial.title
                  );
                }).length &&
                isMobile &&
                !isSideParamPosition
                  ? { top: '-15px' }
                  : {}
              }
              onClick={() => {
                onClickPrevious();
              }}
            >
              <ArrowBackIosNew />
            </IconButton>
          )}
          <ul
            className={`${classes.list} ${isMobile ? 'mobile' : ''}${
              isSideParamPosition ? 'side' : ''
            }`}
          >
            {displayedTestimonials.map((testimonial, index) => {
              return (
                <TestimonialCard
                  key={index}
                  testimonial={testimonial}
                  onClick={() => {
                    setCurrentTestimonialId(testimonial._id);
                    getTestimonials(testimonial, index);
                    setIsOpen(
                      currentTestimonialId !== '' &&
                        currentTestimonialId !== testimonial._id,
                      true,
                    );

                    onHoverIncrement(
                      testimonial,
                      currentTestimonialId != testimonial._id,
                    );
                  }}
                  //Close testimonial element onMouseLeave
                  onMouseLeave={() => {
                    //Set isOpen = false if we click the button
                    if (isScreenSmall(dataWidth)) {
                      setIsOpen(false, false);
                    }
                  }}
                  animateIn={animateIn}
                  isOpen={currentTestimonialId === testimonial._id}
                  isDimmed={
                    currentTestimonialId !== '' &&
                    currentTestimonialId !== testimonial._id
                  }
                  secondaryColor={secondaryColor}
                  isMobile={isMobile}
                  isSideParamPosition={isSideParamPosition}
                  testimonialDimension={testimonialBubbleDimension}
                  testimonialBubbleRadius={testimonialBubbleRadius}
                  outlineBubble={outlineBubble}
                  zIndex={zIndex}
                  openOnClick={triggerItem.openOnClick}
                />
              );
            })}
          </ul>
          {displayNavigation() && (
            <IconButton
              className={`${classes.navButton} ${isMobile ? 'mobile' : ''} ${
                isSideParamPosition ? 'side' : ''
              }`}
              disabled={false}
              style={
                displayedTestimonials.filter((testimonial) => {
                  return (
                    testimonial.title != '' &&
                    testimonial.titlePosition != 'inside' &&
                    testimonial.title != '<p><br></p>' &&
                    testimonial.title
                  );
                }).length &&
                isMobile &&
                !isSideParamPosition
                  ? { top: '-15px' }
                  : {}
              }
              onClick={() => {
                onClickNext();
              }}
            >
              <ArrowForwardIos />
            </IconButton>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
export default TestimonialsElements;
