import React, { useState } from 'react';
import { Link } from '@mui/material';
import KudeoLogo from '../assets/kudeoLogo.svg';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  toggleButton: {
    position: 'fixed',
    width: '100px',
    display: 'flex',
    right: '0px',
    alignItems: 'center important',
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    borderRadius: 20,
    overflow: 'hidden',
    paddingLeft: '4px',
    backgroundColor: 'rgba(145, 158, 171, 0.5)',
    backdropFilter: 'blur(1px)',
    '& img': {
      width: '14px',
      marginRight: '4px',
      marginLeft: '4px',
    },
    '& a': {
      display: 'flex',
      alignItems: 'center important',
      fontSize: '11px',
      textDecoration: 'none',
      padding: '5px 0px',
      paddingRight: 0,
      // textTransform: 'uppercase',
    },
    '& button': {
      fontSize: '11px',
      padding: '0 8px',
      minWidth: 'auto',
      marginLeft: '30px',
    },
  },
});

const Testimonials: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpenedButton, setIsOpenedButton] = useState<boolean>(false);

  return (
    <div
      className={classes.toggleButton}
      onMouseEnter={() => {
        setIsOpenedButton(true);
      }}
      onMouseLeave={() => {
        setIsOpenedButton(false);
      }}
    >
      {isOpenedButton ? (
        <Link href="https://kudeo.co/" target="_blank">
          <img src={KudeoLogo} alt="Kudeo" width="30px" />
          {t('pages.Testimonials.powered')}
          {t('pages.Testimonials.poweredName')}
        </Link>
      ) : (
        <Link href="https://kudeo.co/" target="_blank">
          <img src={KudeoLogo} alt="Kudeo" width="30px" />
          {t('pages.Testimonials.powered')}
          {t('pages.Testimonials.poweredName')}
        </Link>
      )}
    </div>
  );
};

export default Testimonials;
