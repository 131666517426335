import { Button, ClickAwayListener, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import Parser from 'html-react-parser';
import { commonStyles } from '../../src/constants/theme/testimonialCommon';
import { QuoteIcon } from '../../src/constants/svg';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '../helpers/responsive';
import shape from '../constants/theme/shape';
import { Testimonial } from '../slices/testimonial';

const PADDING = '32px';
const PADDING_MOBILE = '16px';

export const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    width: '600px',
    maxWidth: '100%',
  },
  wrapperMobile: {
    position: 'absolute',
    top: '0%',
    width: '600px',
    maxWidth: '100%',
  },
  content: {
    position: 'relative',
    '&:after': {
      content: '""',
      height: '22px',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      background:
        'linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, transparent 100%)',
      borderBottomLeftRadius: shape.borderRadiusMd,
      borderBottomRightRadius: shape.borderRadiusMd,
    },
    [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT}px)`]: {
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
    },
  },
  elementToAnimate: {
    opacity: 0,
    visibility: 'hidden',
    transform: 'scale(0.8) translate3d(128px, 0, 0)',
    transition: 'all .2s',
    '&.is-open': {
      opacity: 1,
      visibility: 'visible',
      transform: 'scale(1) translate3d(0, 0, 0)',
    },
    [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT}px)`]: {
      transform: 'scale(1) translate3d(0, 64px, 0)',
    },
  },
  top: {
    display: 'flex',
    padding: PADDING,
    [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT}px)`]: {
      padding: PADDING_MOBILE,
      paddingTop: '38px',
    },
  },
  companyLogo: {
    width: '72px',
    height: '72px',
    padding: '6px',
    marginRight: '16px',
  },
  companyName: {
    marginTop: '4px',
    fontWeight: '600',
    fontSize: '18px',
    maxWidth: '384px',
  },
  contactName: {
    opacity: '0.7',
    fontSize: '14px',
  },
  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '26px',
  },
  CTA: {
    fontSize: '14px',
    padding: '6px 16px !important', // TODO : handle this without !important
    transform: 'scale(0.8) translate3d(16px, 0, 0)',
    opacity: 0,
    transition: 'transform 0.2s 0.2s, opacity 0.2s 0.2s, background 0.15s',
    '&.is-open': {
      opacity: 1,
      transform: 'scale(1) translate3d(0, 0, 0)',
    },
  },
  quoteIconWrapper: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    '& svg': {
      width: '38px',
      height: '38px',
    },
  },
  backDrop: {
    background: 'rgba(0, 0, 0, 0.1)',
  },
  textWrapper: {
    padding: PADDING,
    paddingTop: 0,
    overflow: 'auto',
    height: '300px',
    '& h1': {
      fontSize: '28px',
      marginBottom: '16px',
      [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT}px)`]: {
        fontSize: '22px',
      },
    },
    '& h2': {
      marginBottom: '8px',
      [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT}px)`]: {
        fontSize: '18px',
      },
    },
    '& p': {
      marginBottom: '8px',
    },
    '& img': {},
  },
});

const TestimonialPopup: React.FC = () => {
  const commonClasses = commonStyles();
  const classes = useStyles();
  const [color, setColor] = useState<{ mainColor: any; secondaryColor: any }>();
  const [displayContent, setDisplayContent] = useState(false);
  const [data, setData] = useState<{ name: string; args: any }>({
    name: '',
    args: '',
  });
  const [testimonial, setTestimonial] = useState<Testimonial>();
  const handleMessage = (event: MessageEvent<{ name: string; args: any }>) => {
    setData(event?.data);
  };
  const [onClose, setOnClose] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const [onMobile, setOnMobile] = useState<boolean>(false);

  const onClickClose = (isClose: boolean) => {
    setOnClose(isClose);
    setDisplayContent(false);
    window.parent.postMessage({ name: 'popUpClose', args: onClose }, '*');
  };

  useEffect(() => {
    if (data.name == 'openTestimonialPopup') {
      setTestimonial(data.args.testimonial);
      setColor(data.args.color);
      setDisplayContent(true);
    }
    if (data.name == 'getWidth') {
      if (data.args < 786) {
        setOnMobile(true);
      }
    }
  }, [data, testimonial]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClickClose(false);
      }}
    >
      <div>
        <div>
          <div className={onMobile ? classes.wrapperMobile : classes.wrapper}>
            <div
              className={`${classes.content} ${
                commonClasses.commonBackground
              } ${classes.elementToAnimate} ${displayContent ? 'is-open' : ''}`}
            >
              <IconButton
                className={commonClasses.closeModalButton}
                onClick={() => {
                  onClickClose(false);
                }}
              >
                <Close />
              </IconButton>
              <div
                className={`${classes.quoteIconWrapper} ${
                  displayContent ? 'is-open' : ''
                }`}
              >
                <QuoteIcon fill={color?.secondaryColor} />
              </div>
              <div className={classes.top}>
                <img
                  className={classes.companyLogo}
                  src={
                    testimonial?.logo &&
                    testimonial?.displayPreferences.showCompanyLogo
                      ? testimonial?.logo
                      : testimonial?.picture
                  }
                  alt="Company logo"
                />
                <div>{testimonial?.information}</div>
              </div>
              <div className={classes.textWrapper}>
                {Parser(testimonial?.details ?? '')}
                {testimonial?.callToActionUrl && (
                  <div className={classes.CTAWrapper}>
                    <Button
                      variant="contained"
                      className={`${classes.CTA} ${commonClasses.CTA} ${
                        displayContent ? 'is-open' : ''
                      }`}
                      href={testimonial?.callToActionUrl ?? ''}
                      target="_blank"
                      size="medium"
                      style={{
                        background: color?.mainColor,
                      }}
                    >
                      {testimonial?.callToActionText}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default TestimonialPopup;
