import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './VideoPlayer.style';
import ReactPlayer from 'react-player/lazy';

interface Props {
  url: string;
  isOpen: boolean;
  isDimmed: boolean;
  animateIn: boolean;
  isMobile?: boolean;
  isSideParamPosition?: boolean;
  bubbleSize: number;
  bubbleRadius?: number;
  outlineBubble?: string;
}

const VideoPlayer: React.VFC<Props> = ({
  url,
  isOpen,
  isDimmed,
  animateIn,
  isMobile,
  isSideParamPosition,
  bubbleSize,
  bubbleRadius,
  outlineBubble,
}) => {
  const classes = useStyles();

  const [content, setContent] = useState<string>(() => {
    if (url.includes('kudeo-documents.s3.eu-west-3.amazonaws.com')) {
      return url.replace(
        'kudeo-documents.s3.eu-west-3.amazonaws.com',
        'd2t50mqmhcn624.cloudfront.net',
      );
    } else {
      return url;
    }
  });
  useEffect(() => {
    if (url.includes('kudeo-documents.s3.eu-west-3.amazonaws.com')) {
      setContent(
        url.replace(
          'kudeo-documents.s3.eu-west-3.amazonaws.com',
          'd2t50mqmhcn624.cloudfront.net',
        ),
      );
    } else {
      setContent(url ?? '');
    }
  }, [url]);

  const ref = useRef<ReactPlayer>(null);

  return (
    <li className={`${classes.wrapper} ${animateIn ? 'animate-in' : ''}`}>
      <button
        className={`${classes.idleButton} ${isOpen ? 'is-open' : ''} ${
          isDimmed ? 'is-dimmed' : ''
        } ${isMobile ? 'mobile' : ''} ${isSideParamPosition ? 'side' : ''}`}
        style={
          outlineBubble
            ? {
                width: `${bubbleSize - 4}px`,
                height: `${bubbleSize - 4}px`,
                outline: `2px solid ${outlineBubble}`,
                outlineOffset: '2px',
                borderRadius: `${bubbleRadius}%`,
                boxShadow: `0 0 0 3px #fff`,
              }
            : {
                width: `${bubbleSize}px`,
                height: `${bubbleSize}px`,
                borderRadius: `${bubbleRadius}%`,
              }
        }
      >
        <div
          className={`${classes.idleButtonImageWrapper} ${
            isMobile ? 'mobile' : ''
          } ${isSideParamPosition ? 'side' : ''}`}
          style={
            url.includes('youtube') || url.includes('vimeo')
              ? {
                  background: 'black',
                  borderRadius: `${bubbleRadius}%`,
                }
              : {
                  borderRadius: `${bubbleRadius}%`,
                }
          }
        >
          <ReactPlayer
            ref={ref}
            className={`${classes.idleButtonImage}  ${
              content.includes('short') ? classes.iframeShort : ''
            } ${isSideParamPosition ? 'side' : ''}`}
            url={content}
            playing={content.includes('youtu') ? true : !isOpen}
            loop={true}
            muted={true}
            playsinline={true}
            config={{
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  showInfo: 0,
                  controls: 0,
                },
              },
            }}
          />
        </div>
      </button>
    </li>
  );
};

export default VideoPlayer;
