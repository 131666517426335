import { stringify } from 'query-string';

import { fetchTimeout } from './fetchTimeout';

const API_URL = process.env.REACT_APP_API_URL;

export type HTTPMethod =
  | 'CONNECT'
  | 'DELETE'
  | 'GET'
  | 'HEAD'
  | 'OPTIONS'
  | 'PATCH'
  | 'PUT'
  | 'POST'
  | 'TRACE';

export class ApiError extends Error {
  statusCode: number;

  constructor(errorMessage: string, statusCode: number) {
    super(errorMessage);

    this.statusCode = statusCode;
  }
}

export const apiRequest = async <T>(
  method: HTTPMethod,
  endpoint: string,
  params?: Record<string, string>,
  body?: FormData | Record<string, unknown>,
) => {
  const accessToken = process.env.ACCESS_TOKEN;
  let query = API_URL + endpoint;

  if (params) {
    query += '?' + stringify(params);
  }

  const fetchConfig: any = {
    method,
    headers: {
      Accept: 'application/json',
    },
  };

  if (accessToken) {
    fetchConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (body) {
    if (body instanceof FormData) {
      fetchConfig.body = body;
      fetchConfig.headers['Content-Type'] = 'multipart/form-data';
    } else {
      fetchConfig.body = JSON.stringify(body);
      fetchConfig.headers['Content-Type'] = 'application/json';
    }
  }

  const response = await fetchTimeout(query, fetchConfig);
  let bodyResult;
  if (response.status !== 204) {
    bodyResult = await response.json();
  }

  if (!response.ok) {
    /* Logs message if Bad request */
    if (response.status === 400) {
      console.log('ERROR Api bad request:', bodyResult?.message);
    }

    throw new ApiError(bodyResult.message, response.status);
  }
  return bodyResult as T;
};
