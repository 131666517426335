import React, {
  LegacyRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Avatar, Button, IconButton } from '@mui/material';
import {
  Testimonial,
  updateStatsVideoPlayedTestimonial,
} from '../slices/testimonial';
import { createUseStyles } from 'react-jss';
import { boxShadow, commonStyles } from '../constants/theme/testimonialCommon';
import shape from '../constants/theme/shape';
import { QuoteIcon } from '../constants/svg';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../hooks/store';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CompanyIcon from '../assets/company.svg';
import { ReactPlayerProps } from 'react-player/types/lib';
import { default as _ReactPlayer } from 'react-player/lazy';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

//Style of testimonial-informations
const useStyles = createUseStyles({
  testimonialContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '12px',
    paddingLeft: '12px',
    gap: '5px',
  },
  //style of content TestimonialsInformation
  content: {
    zIndex: '999',
    alignItems: 'center',
    justifyContent: 'center',
    width: '360px',
    position: 'relative',
    '&.mobile': {
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
  contentVideo: {
    width: 'fit-content',
  },
  contentWritten: {},
  top: {
    padding: '12px',
    borderRadius: shape.borderRadius,
    background: 'white',
    boxShadow: boxShadow,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    bottom: '0px !important',
    justifyContent: 'flex-start',
    alignItems: 'center !important',
    borderRadius: shape.borderRadius,
    '&.square': {
      maxWidth: '315px',
    },
    '&.vertical': {
      maxWidth: '243px',
    },
    '&.horizontal': {
      maxWidth: '360px',
    },
  },

  quoteIconWrapperBig: {
    zIndex: '1',
    position: 'absolute',
    top: '-12px',
    left: '-8px',
    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
  companyIconWrapperBig: {
    zIndex: '1',
    position: 'absolute',
    top: '0px',
    left: '0px',
    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
  date: {
    fontSize: '14px',
    color: 'gray',
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '1000px',
    padding: '2px 8px',
    display: 'inline-block',
    marginLeft: '16px',
  },
  quote: {
    fontSize: '16px',
    padding: '8px',
    lineHeight: '1.4',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '8px',
    objectFit: 'cover',
    '&.is-open': {
      transitionDelay: '0.1s !important',
    },
    '&.mobile': {
      width: '40px',
      height: '40px',
      borderRadius: '50% !important',
      marginRight: '8px',
      bottom: '0px !important',
    },
  },
  contactName: {
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '6px 12px',
    borderRadius: shape.borderRadiusMd,
  },

  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  CTAVideo: {
    position: 'absolute',
    top: '80%',
    right: '5%',
  },
  CTAVideoVertical: {
    position: 'absolute',
    bottom: '10px',
    right: '3%',
  },
  companyName: {
    fontWeight: 'bold',
  },
  position: {
    opacity: 0.7,
  },
  volumeIcon: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: '81%',
    right: '89%',
    opacity: '0.8',
    borderRadius: '50%',
    backgroundColor: 'grey',
    color: 'white',
  },
  volumeIconVertical: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    bottom: '10px',
    right: '82%',
    opacity: '0.8',
    borderRadius: '50%',
    backgroundColor: 'grey',
    color: 'white',
  },
  volumeIconSquare: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    bottom: '10px',
    right: '87%',
    opacity: '0.8',
    borderRadius: '50%',
    backgroundColor: 'grey',
    color: 'white',
  },
  avatarImage: {
    background: 'white',
  },
  commonBackgroundVideo: {
    marginBottom: '4px',
    background: 'transparent',
  },
  background: {
    left: '0px',
    position: 'relative',
    borderRadius: '16px',
    overflow: 'hidden',
  },
  logoContainer: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    width: '70px',
    height: '70px',
  },
  logoCompanyDefault: {
    '& img': {
      width: '28px',
      height: '28px',
    },
  },
  doneIcon: {
    color: 'white',
    width: '20px !important',
    height: '20px !important',
    transition: 'transform 0.3s ease',
  },
  '@keyframes checkAnimation': {
    '0%': {
      transform: 'scale(0)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },

  doneAnimation: {
    transform: 'scale(1.2)',
  },
  textAnimation: {
    transition: 'opacity 0.3s ease',
    opacity: 0,
  },
  textAnimationShow: {
    opacity: 1,
  },
});

const testimonialsInformations: React.FC = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [testimonial, setTestimonial] = useState<Testimonial>();
  const [onClickVideo, setOnClickVideo] = useState<boolean>(false);
  const [onClickVideoPlay, setOnClickVideoPlay] = useState<boolean>(true);
  const [color, setColor] = useState<{ mainColor: any; secondaryColor: any }>();

  const [data, setData] = useState<{ name: string; args: any }>({
    name: '',
    args: '',
  });

  const setIsClose = () => {
    setOnClickVideoPlay(false);
    window.parent.postMessage({ name: 'getIsCloseModal', args: true }, '*');
  };

  const handleMessage = (event: MessageEvent<{ name: string; args: any }>) => {
    setData(event?.data);
  };

  const onClickIncrement = async (testimonial: Testimonial) => {
    if (sessionStorage.getItem('click=' + testimonial._id) === null) {
      sessionStorage.setItem('click=' + testimonial._id, '1');
    } else {
      sessionStorage.setItem(
        'click=' + testimonial._id,
        (
          parseInt(
            sessionStorage.getItem('click=' + testimonial._id) as string,
          ) + 1
        ).toString(),
      );
    }
  };

  const onHoverIncrement = async (testimonial: Testimonial) => {
    if (sessionStorage.getItem('hovered=' + testimonial._id) === null) {
      sessionStorage.setItem('hovered=' + testimonial._id, '1');
    } else {
      sessionStorage.setItem(
        'hovered=' + testimonial._id,
        (
          parseInt(
            sessionStorage.getItem('hovered=' + testimonial._id) as string,
          ) + 1
        ).toString(),
      );
    }
  };

  const openTestimonialPopup = (
    testimonial: Testimonial,
    color: { mainColor: any; secondaryColor: any },
  ) => {
    window.parent.postMessage(
      {
        name: 'openTestimonialPopup',
        args: { testimonial, color },
      },
      '*',
    );
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const [widthApp, setWidthApp] = useState(0);

  const testimonialRef = useRef<HTMLDivElement | undefined>();

  const informationRef = useRef<HTMLDivElement | undefined>();

  const [position, setPosition] = useState<string>('bottom');

  useLayoutEffect(() => {
    const element = testimonialRef.current;
    if (element) {
      const observer = new ResizeObserver((entries: any) => {
        for (const entry of entries) {
          const { height, width } = entry.contentRect;
          window.parent.postMessage(
            {
              name: 'setCardHeight',
              args: {
                height: height,
                width: width,
                isLogo: testimonial?.displayPreferences.showCompanyLogo
                  ? true
                  : false,
              },
            },
            '*',
          );
        }
      });

      observer.observe(element);

      return () => {
        observer.unobserve(element);
      };
    }
  }, [testimonial]);

  useLayoutEffect(() => {
    const element = informationRef.current;
    if (element) {
      const observer = new ResizeObserver((entries: any) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          window.parent.postMessage(
            {
              name: 'informationHeight',
              args: {
                height: height,
              },
            },
            '*',
          );
        }
      });

      observer.observe(element);

      return () => {
        observer.unobserve(element);
      };
    }
  }, [testimonial]);

  useEffect(() => {
    if (data.name === 'setFirstTestimonials') {
      setTestimonial(data.args);
      setOnClickVideoPlay(true);
      //close the bubbles default in mobile
    }
    if (data.name == 'getColor') {
      setColor(data.args);
    }

    if (data.name == 'getPositionPlugin') {
      setPosition(data.args);
    }

    if (data.name == 'addedToCart') {
      setTimeout(() => {
        setDoneStatus(true);
        setIsLoading(false);
      }, 300);
    }
  }, [data]);

  const [device, setDevice] = useState<string>('desktop');

  const [testimonialBubbleSize, setTestimonialBubbleSize] = useState(65);

  useEffect(() => {
    if (data.name == 'getTestimonial') {
      setTestimonial(data.args);
      setOnClickVideoPlay(true);
    }
    if (data.name == 'getColor') {
      setColor(data.args);
    }
    if (data.name == 'getIsOpen') {
      if (data.args === false) {
        setOnClickVideoPlay(false);
      }
    }
    if (data.name == 'getWidth') {
      setWidthApp(data.args);
    }
    if (data.name == 'onClickedNav') {
      setOnClickVideoPlay(false);
    }
    if (data.name == 'getTestimonialBubbleSize') {
      if (data.args === 'small') {
        setTestimonialBubbleSize(70);
      } else if (data.args === 'medium') {
        setTestimonialBubbleSize(80);
      } else if (data.args === 'large') {
        setTestimonialBubbleSize(85);
      }
    }
  }, [data, testimonial]);

  useEffect(() => {
    if (widthApp < 786 && widthApp != 0) {
      setDevice('mobile');
    } else if (widthApp >= 786 && widthApp != 0) {
      setDevice('desktop');
    }
  }, [widthApp]);

  useEffect(() => {
    if (widthApp < 786 && widthApp != 0) {
      setOnClickVideoPlay(false);
    }
  }, [widthApp]);

  const [totalVideoPlayed, setTotalVideoPlayed] = useState(0);

  const [testimonialId, setTestimonialId] = useState<string>();

  useEffect(() => {
    if (testimonial?._id) {
      setTestimonialId(testimonial?._id);
    }
  }, [testimonial]);

  useEffect(() => {
    if (testimonialId) {
      if (localStorage.getItem('testimonial') !== testimonialId) {
        dispatch(
          updateStatsVideoPlayedTestimonial({
            testimonialId: localStorage.getItem('testimonial') as string,
            secondsPlayed: Number.parseFloat(
              localStorage.getItem('totalVideoPlayed') as string,
            ),
            device: device,
          }),
        );
        localStorage.setItem('testimonial', testimonialId);
        localStorage.setItem('totalVideoPlayed', totalVideoPlayed.toString());
      } else {
        localStorage.setItem('totalVideoPlayed', totalVideoPlayed.toString());
      }
    }
  }, [totalVideoPlayed, testimonialId]);

  const handlePageLeave = () => {
    const testimonialIdTemp = localStorage.getItem('testimonial') as string;
    const secondPlayedTemp = Number.parseFloat(
      localStorage.getItem('totalVideoPlayed') as string,
    );
    if (testimonialIdTemp && secondPlayedTemp) {
      dispatch(
        updateStatsVideoPlayedTestimonial({
          testimonialId: testimonialIdTemp,
          secondsPlayed: secondPlayedTemp,
          device: device,
        }),
      );
    }
  };

  const [realWidth, setRealWidth] = useState(0);

  const [realHeight, setRealHeight] = useState(0);

  const [maxWidth, setMaxWidth] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [doneStatus, setDoneStatus] = useState(false);

  useEffect(() => {
    if (doneStatus) {
      setTimeout(() => {
        setDoneStatus(false);
        setIsLoading(false);
      }, 2000);
    }
  }, [doneStatus]);

  useEffect(() => {
    if (testimonial?.displayPreferences.showSquare) {
      setMaxWidth(315);
    } else if (testimonial?.displayPreferences.showVertical) {
      setMaxWidth(243);
    } else {
      setMaxWidth(360);
    }
  }, [testimonial]);

  useEffect(() => {
    if (position === 'bottom' || position === 'top') {
      if (widthApp - 20 > maxWidth) {
        if (testimonial?.displayPreferences.showSquare) {
          setRealWidth(maxWidth);
          setRealHeight(maxWidth);
        } else if (testimonial?.displayPreferences.showVertical) {
          setRealWidth(maxWidth);
          setRealHeight((maxWidth * 16) / 9);
        } else {
          if (testimonial?.testimonialType === 'video') {
            setRealWidth(maxWidth);
            setRealHeight((maxWidth * 9) / 16);
          } else {
            setRealWidth(maxWidth);
          }
        }
      } else {
        if (testimonial?.displayPreferences.showSquare) {
          setRealWidth(widthApp - 20);
          setRealHeight(widthApp - 20);
        } else if (testimonial?.displayPreferences.showVertical) {
          setRealWidth(widthApp - 20);
          setRealHeight(((widthApp - 20) * 16) / 9);
        } else {
          setRealWidth(widthApp - 20);
          setRealHeight(((widthApp - 20) * 9) / 16);
        }
      }
    } else {
      if (widthApp - testimonialBubbleSize > maxWidth) {
        if (testimonial?.displayPreferences.showSquare) {
          setRealWidth(maxWidth);
          setRealHeight(maxWidth);
        } else if (testimonial?.displayPreferences.showVertical) {
          setRealWidth(maxWidth);
          setRealHeight((maxWidth * 16) / 9);
        } else {
          if (testimonial?.testimonialType === 'video') {
            setRealWidth(maxWidth);
            setRealHeight((maxWidth * 9) / 16);
          } else {
            setRealWidth(maxWidth);
          }
        }
      } else {
        if (testimonial?.displayPreferences.showSquare) {
          setRealWidth(widthApp - testimonialBubbleSize);
          setRealHeight(widthApp - testimonialBubbleSize);
        } else if (testimonial?.displayPreferences.showVertical) {
          setRealWidth(widthApp - testimonialBubbleSize);
          setRealHeight(((widthApp - testimonialBubbleSize) * 16) / 9);
        } else {
          setRealWidth(widthApp - testimonialBubbleSize - 15);
          setRealHeight(((widthApp - testimonialBubbleSize - 15) * 9) / 16);
        }
      }
    }
  }, [widthApp, maxWidth, position, testimonial]);

  const [testimonialContent, setTestimonialContent] = useState(() => {
    if (
      testimonial?.content.includes(
        'kudeo-documents.s3.eu-west-3.amazonaws.com',
      )
    ) {
      return testimonial.content.replace(
        'kudeo-documents.s3.eu-west-3.amazonaws.com',
        'd2t50mqmhcn624.cloudfront.net',
      );
    } else {
      return testimonial?.content;
    }
  });

  useEffect(() => {
    if (
      testimonial?.content.includes(
        'kudeo-documents.s3.eu-west-3.amazonaws.com',
      )
    ) {
      setTestimonialContent(
        testimonial?.content.replace(
          'kudeo-documents.s3.eu-west-3.amazonaws.com',
          'd2t50mqmhcn624.cloudfront.net',
        ),
      );
    } else {
      setTestimonialContent(testimonial?.content ?? '');
    }
  }, [testimonial?.content]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      handlePageLeave();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [animateDone, setAnimateDone] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (doneStatus) {
      setTimeout(() => {
        setAnimateDone(true);
      }, 100);
    } else {
      setShowText(false);
      setTimeout(() => {
        setShowText(true);
        setAnimateDone(false);
      }, 300);
    }
  }, [doneStatus]);

  const playerRef = useRef<_ReactPlayer>(null);

  return (
    <div
      className={classes.testimonialContainer}
      ref={testimonialRef as LegacyRef<HTMLDivElement>}
      onMouseEnter={() => {
        if (testimonial) onHoverIncrement(testimonial);
      }}
    >
      {testimonial?.testimonialType === 'video' ? (
        <div className={`${classes.content} ${classes.contentVideo}`}>
          <div
            className={`${commonClasses.commonBackground} ${classes.commonBackgroundVideo} ${classes.background}`}
          >
            <ReactPlayer
              ref={playerRef}
              url={testimonialContent}
              playing={onClickVideoPlay}
              loop={true}
              volume={onClickVideo ? 1 : 0}
              mute={onClickVideo ? false : true}
              playsinline={true}
              width={realWidth}
              height={realHeight}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    showInfo: 0,
                    controls: 0,
                    ivLoadPolicy: 3,
                    rel: 0,
                  },
                },
              }}
              onClick={() => {
                setOnClickVideoPlay((onClickVideoPlay) => !onClickVideoPlay);
              }}
              onProgress={(e) => {
                setTotalVideoPlayed(e.playedSeconds);
              }}
            />
            {testimonial?.callToActionText &&
              (testimonial?.callToActionUrl ? (
                <div
                  className={`${classes.CTAWrapper} ${classes.CTAVideoVertical}`}
                >
                  <Button
                    variant="contained"
                    href={testimonial?.callToActionUrl}
                    target="_blank"
                    className={commonClasses.CTA}
                    size="small"
                    style={{
                      background: testimonial.color
                        ? testimonial.color
                        : color?.mainColor,
                    }}
                    onClick={() => {
                      setOnClickVideo(false);
                      onClickIncrement(testimonial);
                    }}
                  >
                    {testimonial.callToActionText}
                  </Button>
                </div>
              ) : (
                testimonial?.variantProductId != '' && (
                  <div
                    className={`${classes.CTAWrapper} ${classes.CTAVideoVertical}`}
                  >
                    <Button
                      variant="contained"
                      className={commonClasses.CTA}
                      size="small"
                      style={{
                        background: testimonial.color
                          ? testimonial.color
                          : color?.mainColor,
                      }}
                      onClick={() => {
                        setOnClickVideo(false);
                        onClickIncrement(testimonial);

                        window.parent.postMessage(
                          {
                            name: 'fetchProductFromShopify',
                            args: testimonial.variantProductId,
                          },
                          '*',
                        );
                        setIsLoading(true);
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress
                          sx={{
                            color: 'white',
                            width: '20px !important',
                            height: '20px !important',
                          }}
                        />
                      ) : doneStatus ? (
                        <DoneIcon
                          className={`${classes.doneIcon} ${
                            animateDone && classes.doneAnimation
                          }`}
                        />
                      ) : (
                        <div
                          className={`${classes.textAnimation} ${
                            showText && classes.textAnimationShow
                          }`}
                        >
                          {testimonial.callToActionText}
                        </div>
                      )}
                    </Button>
                  </div>
                )
              ))}
            <IconButton
              className={`${
                testimonial.displayPreferences.showVertical
                  ? classes.volumeIconVertical
                  : testimonial.displayPreferences.showSquare
                  ? classes.volumeIconSquare
                  : classes.volumeIcon
              }`}
              onClick={() => {
                setOnClickVideo((onClickVideo) => !onClickVideo);
              }}
            >
              {onClickVideo ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </IconButton>
            <IconButton
              className={commonClasses.closeModalButton}
              onClick={() => {
                setIsClose();
              }}
              style={{
                borderRadius: '50%',
                backgroundColor: 'grey',
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </div>
          {testimonial.displayPreferences.showCompanyLogo && (
            <div className={classes.companyIconWrapperBig}>
              <Avatar
                className={
                  !testimonial?.logo
                    ? `${classes.logoContainer} ${classes.logoCompanyDefault}`
                    : `${classes.logoContainer}`
                }
                src={testimonial?.logo ? testimonial?.logo : CompanyIcon}
                style={
                  testimonial?.logo
                    ? {}
                    : { color: 'white', backgroundColor: '#FFD600' }
                }
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {testimonial?.content && (
            <div
              className={`${classes.content} ${classes.contentWritten}`}
              style={{
                width: `${realWidth}px`,
              }}
            >
              <div
                className={`${classes.top} ${commonClasses.commonBackground}`}
              >
                {testimonial?.displayPreferences.showDate && (
                  <div className={classes.date}>
                    {testimonial.createdAt
                      ?.split('T')[0] // TODO : WTF is this, date should be parsed
                      .split('-')
                      .reverse()
                      .join('-')
                      ?.replaceAll('-', '/')}
                  </div>
                )}
                {testimonial?.content && (
                  <p className={classes.quote} id={'testimonial-content'}>
                    « {testimonial?.content} »
                  </p>
                )}

                {testimonial?.displayPreferences.showMoreInfos &&
                testimonial?.details &&
                testimonial?.details.length ? (
                  <div className={classes.CTAWrapper}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (color && testimonial) {
                          openTestimonialPopup(testimonial, color);
                        }
                        onClickIncrement(testimonial);
                      }}
                      className={`${commonClasses.CTA}`}
                      size="small"
                      style={{
                        background: testimonial.color
                          ? testimonial.color
                          : color?.mainColor,
                      }}
                    >
                      {t('pages.Testimonials.moreInformationCallToAction')}
                    </Button>
                  </div>
                ) : (
                  testimonial?.callToActionText &&
                  testimonial?.callToActionUrl && (
                    <div className={classes.CTAWrapper}>
                      <Button
                        variant="contained"
                        href={
                          testimonial?.callToActionUrl.startsWith('http')
                            ? testimonial?.callToActionUrl
                            : `https://${testimonial?.callToActionUrl}`
                        }
                        target="_blank"
                        className={commonClasses.CTA}
                        size="small"
                        style={{
                          background: testimonial.color
                            ? testimonial.color
                            : color?.mainColor,
                        }}
                        onClick={() => {
                          onClickIncrement(testimonial);
                          setOnClickVideo(false);
                        }}
                      >
                        {testimonial.callToActionText}
                      </Button>
                    </div>
                  )
                )}
                <IconButton
                  className={commonClasses.closeModalButton}
                  onClick={() => {
                    setIsClose();
                  }}
                >
                  <Close />
                </IconButton>
                <div className={classes.quoteIconWrapperBig}>
                  <QuoteIcon fill={color?.secondaryColor} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div
        className={`${classes.bottom} ${
          testimonial?.displayPreferences.showSquare
            ? 'square'
            : testimonial?.displayPreferences.showVertical
            ? 'vertical'
            : 'horizontal'
        }`}
        style={{ maxWidth: `${realWidth}px` }}
        ref={informationRef as LegacyRef<HTMLDivElement>}
      >
        {testimonial?.displayPreferences.showCustomerPicture && (
          <Avatar
            className={`${classes.avatar} ${commonClasses.commonBackground}`}
            src={testimonial?.picture}
          />
        )}
        {testimonial && testimonial.information.length > 0 && (
          <div
            className={`${classes.contactName} ${commonClasses.commonBackground}`}
          >
            {testimonial?.information}
          </div>
        )}
      </div>
    </div>
  );
};
export default testimonialsInformations;
