import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ButtonTestimonials from '../pages/ButtonTestimonials';
import TestimonialsElements from '../pages/TestimonialsElements';
import testimonialsInformations from '../pages/TestimonialsInformations';
import TestimonialsPopup from '../pages/TestimonialsPopup';
import TestimonialsEmbedded from 'src/pages/TestimonialsEmbedded';

export const GuestSwitch: React.FC = () => {
  return (
    <Switch>
      <Route path="/buttonTestimonials" component={ButtonTestimonials} />
      <Route path="/testimonialsElement" component={TestimonialsElements} />
      <Route
        path="/testimonialsInformations"
        component={testimonialsInformations}
      />
      <Route path="/testimonialsPopup" component={TestimonialsPopup} />
      <Route path="/testimonialsEmbbeded" component={TestimonialsEmbedded} />
    </Switch>
  );
};
