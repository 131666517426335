import { createUseStyles } from 'react-jss';
import shape from './shape';

export const boxShadow = '0 2px 15px rgba(0, 0, 0, 0.1)';
export const easing = 'cubic-bezier(0.23, 1, 0.32, 1)';

export const commonStyles = createUseStyles({
  commonBackground: {
    boxShadow,
    background: 'rgba(255, 255, 255, 0.97)',
    backdropFilter: 'blur(20px)',
    borderRadius: shape.borderRadiusMd,
  },
  closeModalButton: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: '4px',
    right: '4px',
    opacity: '0.6',
  },
  CTA: {
    fontSize: '12px',
    boxShadow: 'none',
    padding: '4px 12px !important',
    borderRadius: '1000px',
    color: 'white',
    transition: '0.15s',
    '&:hover': {
      opacity: '0.7',
      color: 'white',
    },
  },
});
