import React, { useEffect, useRef, useState } from 'react';
import { Testimonial } from '../../slices/testimonial';
import { useStyles } from './TestimonialCard.style';
import { QuoteIcon } from '../../constants/svg';
import VideoPlayer from './VideoPlayer';

interface Props {
  onClick: React.Dispatch<React.SetStateAction<string>>;
  testimonial: Testimonial;
  isOpen: boolean;
  isDimmed: boolean;
  animateIn: boolean;
  secondaryColor: string;
  onMouseLeave: React.Dispatch<React.SetStateAction<string>>;
  isMobile?: boolean;
  isSideParamPosition?: boolean;
  testimonialDimension?: string;
  testimonialBubbleRadius?: number;
  outlineBubble?: string;
  zIndex?: number;
  openOnClick?: boolean;
}

const TestimonialCard: React.VFC<Props> = ({
  onClick,
  testimonial,
  isOpen,
  isDimmed,
  animateIn,
  secondaryColor,
  onMouseLeave,
  isMobile,
  isSideParamPosition,
  testimonialDimension,
  testimonialBubbleRadius,
  outlineBubble,
  zIndex,
  openOnClick,
}) => {
  const classes = useStyles();

  const [bubbleSize, setBubbleSize] = useState<number>(56);

  const containerRef = useRef<HTMLDivElement>(null);

  const titleRef = useRef<HTMLParagraphElement>(null);

  const [policeSize, setPoliceSize] = useState(16);

  useEffect(() => {
    if (containerRef.current && titleRef.current) {
      const hauteurDiv = containerRef.current.offsetHeight;
      const largeurDiv = containerRef.current.offsetWidth;
      const hauteurTexte = titleRef.current.offsetHeight;
      const largeurTexte = titleRef.current.offsetWidth;

      const ratioHauteur = hauteurDiv / hauteurTexte;
      const ratioLargeur = largeurDiv / largeurTexte;

      const nouveauRatio = Math.min(ratioHauteur, ratioLargeur);
      if (policeSize * nouveauRatio > 15) {
        setPoliceSize(15);
      } else {
        setPoliceSize((prevSize) => prevSize * nouveauRatio);
      }
    }
  }, [containerRef, titleRef]);

  useEffect(() => {
    if (testimonialDimension === 'small') {
      setBubbleSize(56);
    } else if (testimonialDimension === 'medium') {
      setBubbleSize(63);
    } else if (testimonialDimension === 'large') {
      setBubbleSize(70);
    }
  }, [testimonialDimension]);

  return (
    <li
      onClick={() => {
        onClick(isOpen ? '' : testimonial._id);
      }}
      onMouseEnter={() => {
        if (openOnClick === false) {
          onClick(isOpen ? '' : testimonial._id);
        }
      }}
      className={`${classes.wrapper}  ${isOpen ? 'is-open' : ''} ${
        isDimmed ? 'is-dimmed' : ''
      }`}
    >
      <div
        className={`${classes.bubbleAnimation}  ${isOpen ? 'is-open' : ''} ${
          isDimmed ? 'is-dimmed' : ''
        }`}
      >
        {testimonial.testimonialType === 'video' ? (
          <>
            <div className={`${classes.testimonialVideoContainer}`}>
              <div style={{ pointerEvents: 'none' }}>
                <VideoPlayer
                  url={testimonial.content}
                  animateIn={animateIn}
                  isOpen={isOpen}
                  isDimmed={isDimmed}
                  isMobile={isMobile}
                  isSideParamPosition={isSideParamPosition}
                  bubbleSize={bubbleSize}
                  bubbleRadius={testimonialBubbleRadius}
                  outlineBubble={outlineBubble}
                />
              </div>
            </div>
            {testimonial.logo && !outlineBubble && (
              <div
                className={`${classes.quoteIconWrapper} ${
                  isOpen ? 'is-open' : ''
                } ${isDimmed ? 'is-dimmed' : ''}`}
                style={{
                  background: secondaryColor,
                }}
              >
                <img
                  src={testimonial.logo}
                  className={`${classes.quoteIconLogo}`}
                  alt="Company logo"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <button
              className={`${classes.idleButton} ${isOpen ? 'is-open' : ''} ${
                isDimmed ? 'is-dimmed' : ''
              } ${isMobile ? 'mobile' : ''} ${
                isSideParamPosition ? 'side' : ''
              }`}
              onClick={() => onClick(isOpen ? '' : testimonial._id)}
              onMouseEnter={() => {
                onClick(isOpen ? '' : testimonial._id);
              }}
              onMouseLeave={() => {
                onMouseLeave('');
              }}
              style={{
                width: `${bubbleSize}px`,
                height: `${bubbleSize}px`,
              }}
            >
              <div
                className={classes.idleButtonImageWrapper}
                style={{ borderRadius: `${testimonialBubbleRadius}%` }}
              >
                <img
                  className={classes.idleButtonImage}
                  src={
                    testimonial?.logo &&
                    testimonial.displayPreferences.showCompanyLogo
                      ? testimonial.logo
                      : testimonial.picture
                  }
                  alt="Company logo"
                />
              </div>
              <div
                className={`${classes.quoteIconWrapper} ${
                  isOpen ? 'is-open' : ''
                } ${isDimmed ? 'is-dimmed' : ''}`}
                style={{
                  background: secondaryColor,
                }}
              >
                <QuoteIcon />
              </div>
            </button>
          </>
        )}
      </div>

      {testimonial.title != '<p><br></p>' && testimonial.title && (
        <div
          className={`${classes.title} ${
            testimonial.titlePosition === 'inside' ? 'inside' : 'bottom'
          } ${isOpen ? 'is-open' : ''} ${isDimmed ? 'is-dimmed' : ''}`}
          style={
            testimonial.titlePosition === 'inside'
              ? {
                  zIndex: zIndex,
                  height: 'fit-content',
                  width: 'fit-content',
                  maxHeight: `${bubbleSize}px`,
                  maxWidth: `75px`,
                  padding: '5px',
                  lineHeight: '1',
                  transformOrigin: 'center',
                  color: `${testimonial.titleColor}`,
                }
              : {
                  zIndex: zIndex,
                  height: 'fit-content',
                  width: 'fit-content',
                  maxHeight: `${bubbleSize}px`,
                  maxWidth: `${bubbleSize}px`,
                  color: `${testimonial.titleColor}`,
                  lineHeight: '1',
                  borderRadius: `0px`,
                }
          }
          ref={containerRef}
        >
          <p ref={titleRef} style={{ fontSize: `${policeSize}px` }}>
            {testimonial.title}
          </p>
        </div>
      )}
    </li>
  );
};

export default TestimonialCard;
