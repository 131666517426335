import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import testimonialReducer, { testimonialsAdapter } from '../slices/testimonial';

/* Reducers */
const rootReducer = combineReducers({
  testimonials: testimonialReducer,
});

export const store = configureStore({ reducer: rootReducer });

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const testimonialsSelector = testimonialsAdapter.getSelectors<RootState>(
  (state) => state.testimonials.testimonials,
);
