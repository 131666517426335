import { createUseStyles } from 'react-jss';
import shape from '../../constants/theme/shape';
import { boxShadow } from '../../constants/theme/testimonialCommon';
import { Breakpoints } from '../../helpers/responsive';

const idleButtonWidth = '56px';

export const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3px',
  },
  bubbleAnimation: {
    transform: 'scale(0.96)',
    transition: 'all .15s',
    '&:hover': {
      transform: 'scale(1)',
    },
    '&.is-open': {
      transform: 'scale(1)',
    },
    '&.is-dimmed': {
      transform: 'scale(0.85)',
      opacity: '0.7',
    },
  },
  title: {
    display: 'flex',
    textAlign: 'center',
    overflow: 'hidden',
    padding: '1px',
    fontSize: '15px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    width: '100%',
    zIndex: '200',
    textShadow:
      '0.75px 0.75px 0 #000, -0.75px -0.75px 0 #000,0.75px -0.75px 0 #000,-0.75px 0.75px 0 #000',
    '&.is-dimmed': {
      opacity: '0.7',
    },
    '&.inside': {
      position: 'absolute',
      transform: 'translate(50%, -50%)',
      top: '50%',
      right: '50%',
    },
  },
  '*': {
    outline: '1px solid red !important',
  },
  idleButton: {
    border: '0',
    appearance: 'none',
    background: 'transparent',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    padding: '0 !important',
    margin: '0 !important',
    transform: 'scale(0.96)',
    transition: 'all .20s',
    '&:hover': {
      transform: 'scale(1)',
    },
    '&.is-open': {
      transform: 'scale(1)',
    },
    '&.is-dimmed': {
      transform: 'scale(0.85)',
      opacity: '0.7',
      '&:hover': {
        transform: 'scale(0.85)',
      },
    },
    '&.mobile': {
      width: '60px',
      height: '60px',
      '&.side': {
        width: '46px',
        height: '46px',
      },
    },
  },
  idleButtonImageWrapper: {
    width: '100%',
    height: '100%',
    background: 'white',
    boxShadow: boxShadow,
    overflow: 'hidden',
    padding: '6px',
  },
  idleButtonImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },

  quoteIconWrapper: {
    zIndex: '999',
    position: 'absolute',
    float: 'left',
    top: '0',
    left: '0',
    background: 'white',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    transition: 'all .15s',
    '&.is-open, &.is-dimmed': {
      visibility: 'hidden',
      opacity: 0,
      transform: 'scale(0.5)',
    },
    '& svg': {
      width: '70%',
      margin: 'auto',
      marginTop: '-2px',
    },
  },
  content: {
    position: 'absolute',
    top: '0',
    right: `calc(${idleButtonWidth} + 16px)`,
    width: '352px',
    transform: 'scale(0.8) translate3d(48px, -32px, 0)',
  },
  top: {
    padding: '12px',
    marginBottom: '8px',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  quoteIconWrapperBig: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
  date: {
    fontSize: '14px',
    color: 'gray',
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '1000px',
    padding: '2px 8px',
    display: 'inline-block',
    marginLeft: '16px',
  },
  quote: {
    fontSize: '16px',
    padding: '8px',
    lineHeight: '1.4',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '8px',
    objectFit: 'cover',
    transform: 'scale(0.7) translate3d(48px, 0, 0)',
    '&.is-open': {
      transitionDelay: '0.1s !important',
    },
  },
  contactName: {
    fontSize: '14px',
    padding: '6px 12px',
    borderRadius: shape.borderRadiusMd,
    transform: 'scale(0.9) translate3d(8px, 0, 0)',
    '&.is-open': {
      transitionDelay: '0.2s !important',
    },
  },
  elementToAnimate: {
    opacity: 0,
    visibility: 'hidden',
    transformOrigin: 'left left',
    transition: 'all .15s 0s !important',
    '&.is-open': {
      visibility: 'visible',
      opacity: 1,
      transform: 'scale(1) translate3d(0, 0, 0)',
    },
  },
  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  CTA: {
    [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT_MINI}px)`]: {
      fontSize: '10px',
    },
  },
  companyName: {
    fontWeight: 'bold',
  },
  position: {
    opacity: 0.7,
  },
  testimonialVideoContainer: {
    zIndex: '200',
    bottom: '0px',
    position: 'relative',
    background: 'transparent',
  },
  quoteIconLogo: {
    borderRadius: '50%',
  },
});
