import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    listStyle: 'none',
    borderRadius: '10px',
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    position: 'relative',
    borderRadius: '10px',
    overflow: 'hidden',
  },

  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '10px',
    right: '3%',
  },

  volumeIconVertical: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    opacity: '0.8',
    borderRadius: '50%',
    backgroundColor: 'grey',
    color: 'white',
  },

  typography: {
    position: 'absolute',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '700',
    fontStyle: 'normal',
  },

  doneIcon: {
    color: 'white',
    width: '20px !important',
    height: '20px !important',
    transition: 'transform 0.3s ease',
  },
  '@keyframes checkAnimation': {
    '0%': {
      transform: 'scale(0)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },

  doneAnimation: {
    transform: 'scale(1.2)',
  },
  textAnimation: {
    transition: 'opacity 0.3s ease',
    opacity: 0,
  },
  textAnimationShow: {
    opacity: 1,
  },
});
