import { createUseStyles } from 'react-jss';
import shape from '../../constants/theme/shape';
import { boxShadow } from '../../constants/theme/testimonialCommon';
import { Breakpoints } from '../../helpers/responsive';

const idleButtonWidth = '56px';

export const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  idleButton: {
    border: '0',
    appearance: 'none',
    background: 'transparent',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    padding: '0 !important',
    margin: '0 !important',
    '&.mobile': {
      width: '60px',
      height: '60px',
      '&.side': {
        width: '46px',
        height: '46px',
      },
    },
  },
  idleButtonImageWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    boxShadow: boxShadow,
    overflow: 'hidden',
    border: 'none',
    flexWrap: 'wrap',
    alignContent: 'center',
    '&.mobile': {
      '&.side': {
        padding: '0px',
      },
    },
    '& iframe': {
      transform: 'scale(1.9)',
    },
  },
  iframeShort: {
    '& iframe': {
      transform: 'translate(10px,-58px) scale(3.25) !important',
    },
  },

  idleButtonImage: {
    width: '100% !important',
    height: '100% !important',
    borderRadius: '50%',
    '&.mobile': {
      '&.side': {
        width: '100% !important',
        height: '100% !important',
        borderRadius: '50%',
        margin: '0px',
      },
    },
    '& video': { objectFit: 'cover' },
  },
  quoteIconWrapper: {
    zIndex: '99999',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'white',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    '&.is-open, &.is-dimmed': {
      visibility: 'hidden',
      opacity: 0,
      transform: 'scale(0.5)',
    },
    '& svg': {
      width: '70%',
      margin: 'auto',
      marginTop: '-2px',
    },
  },
  content: {
    position: 'absolute',
    top: '0',
    right: `calc(${idleButtonWidth} + 16px)`,
    width: '352px',
  },
  top: {
    padding: '12px',
    marginBottom: '8px',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center important',
    paddingLeft: '8px',
  },
  quoteIconWrapperBig: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
  date: {
    fontSize: '14px',
    color: 'gray',
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '1000px',
    padding: '2px 8px',
    display: 'inline-block',
    marginLeft: '16px',
  },
  quote: {
    fontSize: '16px',
    padding: '8px',
    lineHeight: '1.4',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '8px',
    objectFit: 'cover',
  },
  contactName: {
    fontSize: '14px',
    padding: '6px 12px',
    borderRadius: shape.borderRadiusMd,
  },
  elementToAnimate: {
    opacity: 0,
    visibility: 'hidden',
    transformOrigin: 'left left',
    transition: 'all .15s 0s !important',
    '&.is-open': {
      visibility: 'visible',
      opacity: 1,
      transform: 'scale(1) translate3d(0, 0, 0)',
    },
  },
  CTAWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  CTA: {
    [`@media (max-width:${Breakpoints.MOBILE_BREAKPOINT_MINI}px)`]: {
      fontSize: '10px',
    },
  },
  companyName: {
    fontWeight: 'bold',
  },
  position: {
    opacity: 0.7,
  },
});
