import React, { useEffect, useState } from 'react';
import { useStyles } from './EmbeddedPlayer.style';
import ReactPlayer from 'react-player/lazy';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { Testimonial } from 'src/slices/testimonial';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { commonStyles } from '../../constants/theme/testimonialCommon';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';

interface Props {
  testimonial: Testimonial;
  mainColor: string;
  size: number;
  outline: string;
  visibleElements: string[];
  totalVideoPlayed: Record<string, number>;
  setTotalVideoPlayed: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  doneStatus: boolean;
  setDoneStatus: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmbeddedPlayer: React.VFC<Props> = ({
  testimonial,
  mainColor,
  size,
  outline,
  visibleElements,
  totalVideoPlayed,
  setTotalVideoPlayed,
  doneStatus,
  setDoneStatus,
  isLoading,
  setIsLoading,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const { t } = useTranslation();

  const [content, setContent] = useState<string>(() => {
    if (
      testimonial.content.includes('kudeo-documents.s3.eu-west-3.amazonaws.com')
    ) {
      return testimonial.content.replace(
        'kudeo-documents.s3.eu-west-3.amazonaws.com',
        'd2t50mqmhcn624.cloudfront.net',
      );
    } else {
      return testimonial.content;
    }
  });

  const [onClickVideoPlay, setOnClickVideoPlay] = useState<boolean>(true);

  const [onClickVideo, setOnClickVideo] = useState<boolean>(false);

  useEffect(() => {
    if (
      testimonial.content.includes('kudeo-documents.s3.eu-west-3.amazonaws.com')
    ) {
      setContent(
        testimonial.content.replace(
          'kudeo-documents.s3.eu-west-3.amazonaws.com',
          'd2t50mqmhcn624.cloudfront.net',
        ),
      );
    } else {
      setContent(testimonial.content ?? '');
    }
  }, [testimonial.content]);

  const onClickIncrement = async (testimonial: Testimonial) => {
    if (sessionStorage.getItem('click=' + testimonial._id) === null) {
      sessionStorage.setItem('click=' + testimonial._id, '1');
    } else {
      sessionStorage.setItem(
        'click=' + testimonial._id,
        (
          parseInt(
            sessionStorage.getItem('click=' + testimonial._id) as string,
          ) + 1
        ).toString(),
      );
    }
  };
  const [prevTime, setPrevTime] = useState(0);

  const [animateDone, setAnimateDone] = useState(false);

  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (doneStatus) {
      setTimeout(() => {
        setDoneStatus(false);
        setIsLoading(false);
      }, 2000);
    }
  }, [doneStatus]);

  useEffect(() => {
    if (doneStatus) {
      setTimeout(() => {
        setAnimateDone(true);
      }, 100);
    } else {
      setShowText(false);
      setTimeout(() => {
        setShowText(true);
        setAnimateDone(false);
      }, 300);
    }
  }, [doneStatus]);

  return (
    <li className={`${classes.wrapper}`}>
      <div className={`${classes.content}`}>
        <ReactPlayer
          url={content}
          playing={onClickVideoPlay}
          loop={true}
          volume={onClickVideo ? 1 : 0}
          mute={onClickVideo ? false : true}
          playsinline={true}
          style={{
            outline: outline,
          }}
          width={(size * 9) / 16}
          height={size}
          config={{
            youtube: {
              playerVars: {
                start: 0,
                modestbranding: 1,
                showInfo: 0,
                controls: 0,
                ivLoadPolicy: 3,
                rel: 0,
              },
            },
          }}
          onClick={() => {
            setOnClickVideoPlay((onClickVideoPlay) => !onClickVideoPlay);
          }}
          onProgress={(e) => {
            if (visibleElements.includes(testimonial._id)) {
              let elapsedSeconds = Math.round(e.playedSeconds) - prevTime;

              if (e.playedSeconds < prevTime) {
                elapsedSeconds = e.playedSeconds;
                setPrevTime(0);
              }

              setTotalVideoPlayed((prevTotalVideoPlayed) => {
                // Mettez à jour totalVideoPlayed pour cette vidéo
                const newTotalVideoPlayed = {
                  ...prevTotalVideoPlayed,
                  [testimonial._id]:
                    (prevTotalVideoPlayed[testimonial._id] || 0) +
                    elapsedSeconds,
                };

                // Retourne la nouvelle copie de totalVideoPlayed pour déclencher un nouveau rendu
                return newTotalVideoPlayed;
              });
              setPrevTime(e.playedSeconds);
            }
          }}
        />

        {testimonial?.callToActionText && testimonial?.callToActionUrl ? (
          <div className={`${classes.CTAWrapper}`}>
            <Button
              variant="contained"
              href={testimonial?.callToActionUrl}
              target="_blank"
              className={commonClasses.CTA}
              size="small"
              style={{
                background: testimonial.color ? testimonial.color : mainColor,
              }}
              onClick={() => {
                setOnClickVideo(false);
                onClickIncrement(testimonial);
              }}
            >
              {testimonial.callToActionText}
            </Button>
          </div>
        ) : (
          testimonial?.variantProductId != '' && (
            <div className={`${classes.CTAWrapper}`}>
              <Button
                variant="contained"
                className={commonClasses.CTA}
                size="small"
                style={{
                  background: testimonial.color ? testimonial.color : mainColor,
                }}
                onClick={() => {
                  setOnClickVideo(false);
                  onClickIncrement(testimonial);

                  window.parent.postMessage(
                    {
                      name: 'fetchProductFromShopify',
                      args: testimonial.variantProductId,
                    },
                    '*',
                  );
                  setIsLoading(true);
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{
                      color: 'white',
                      width: '20px !important',
                      height: '20px !important',
                    }}
                  />
                ) : doneStatus ? (
                  <DoneIcon
                    className={`${classes.doneIcon} ${
                      animateDone && classes.doneAnimation
                    }`}
                  />
                ) : (
                  <div
                    className={`${classes.textAnimation} ${
                      showText && classes.textAnimationShow
                    }`}
                  >
                    {testimonial.callToActionText}
                  </div>
                )}
              </Button>
            </div>
          )
        )}
        <IconButton
          className={`${classes.volumeIconVertical}`}
          onClick={() => {
            setOnClickVideo((onClickVideo) => !onClickVideo);
          }}
        >
          {onClickVideo ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>

        <div
          className={classes.typography}
          style={
            testimonial.titleEmbeddedPosition === 'top'
              ? {
                  top: '40px',
                  transform: 'translateX(50%)',
                  right: '50%',
                  color: `${testimonial.titleColorEmbedded}`,
                }
              : testimonial.titleEmbeddedPosition === 'bottom'
              ? {
                  bottom: '40px',
                  transform: 'translateX(50%)',
                  right: '50%',
                  color: `${testimonial.titleColorEmbedded}`,
                }
              : testimonial.titleEmbeddedPosition === 'center'
              ? {
                  transform: 'translate(50%, 50%)',
                  right: '50%',
                  top: '50%',
                  color: `${testimonial.titleColorEmbedded}`,
                }
              : {
                  bottom: '10px',
                  transform: 'translateX(50%)',
                  right: '50%',
                  color: `${testimonial.titleColorEmbedded}`,
                }
          }
        >
          {testimonial.titleEmbedded}
        </div>
      </div>
    </li>
  );
};

export default EmbeddedPlayer;
