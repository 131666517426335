import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  Testimonial,
  getPublishedByWebsite,
  updateTestimonial,
} from 'src/slices/testimonial';
import { testimonialsSelector } from 'src/store';
import EmbeddedPlayer from 'src/components/TestimonialCard/EmbeddedPlayer';
import { IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

//Style of testimonial-informations
const useStyles = createUseStyles({
  testimonialContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    overflowX: 'auto',
    position: 'relative',
    overflow: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  container: {
    display: 'grid',
    justifyContent: 'center',
    overflowX: 'hidden',
  },

  navButton: {
    zIndex: 200,
    width: '50px',
    height: '50px',
    position: 'fixed',
    opacity: '0.7',
    top: '50%',
    transform: 'translateY(-50%)',
    '&.right': {
      right: '0',
    },
    '&.left': {
      left: '0',
    },
  },
});

const TestimonialsEmbedded: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const testimonials: Testimonial[] = useAppSelector(
    testimonialsSelector.selectAll,
  );

  const { mainColor, tags, secondaryColor, userId } = useAppSelector(
    ({ testimonials: { mainColor, tags, secondaryColor, userId } }) => ({
      mainColor,
      tags,
      secondaryColor,
      userId,
    }),
  );

  const [testimonialsList, setTestimonialsList] = useState<Testimonial[]>([]);

  const [data, setData] = useState<{ name: string; args: any }>({
    name: '',
    args: '',
  });

  const handleMessage = (event: MessageEvent<{ name: string; args: any }>) => {
    setData(event?.data);
  };

  const [displayNavigation, setDisplayNavigation] = useState(false);

  const [hovered, setHovered] = useState(false);

  const [videoSize, setVideoSize] = useState<number>(432);

  const [videoOutline, setVideoOutline] = useState<string>('');

  useEffect(() => {
    if (tags.length > 0) {
      tags.map((tag) => {
        if (tag.displayLocation.showOnEmbedded) {
          if (tag.embeddedSettings.videoSize != undefined) {
            setVideoSize(
              tag.embeddedSettings.videoSize === 'small'
                ? 300
                : tag?.embeddedSettings.videoSize === 'medium'
                ? 380
                : 432,
            );
          } else {
            setVideoSize(432);
          }

          setVideoOutline(tag.embeddedSettings.videoOutline ?? '');
        }
      });
    }
  });

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      if (element.scrollWidth > element.clientWidth && hovered) {
        setDisplayNavigation(true);
      } else {
        setDisplayNavigation(false);
      }
    }
  }, [containerRef.current?.clientWidth, hovered]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await updateStats();
    }, 30000);
    return () => clearInterval(interval);
  }, [userId]);

  const updateStats = async () => {
    const listTags = tags.map((tag) => tag.tag);
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key?.includes('hovered=')) {
        if (userId) {
          dispatch(
            await updateTestimonial({
              testimonialId: key.substring(key.indexOf('=') + 1),
              whichStats: 'hover',
              associatedTag: listTags,
              device: isMobile ? 'mobile' : 'desktop',
              numberOfStats: parseInt(sessionStorage.getItem(key) ?? '0') ?? 0,
              userId: userId,
            }),
          );
        }
      } else if (key?.includes('click=')) {
        if (userId) {
          dispatch(
            await updateTestimonial({
              testimonialId: key.substring(key.indexOf('=') + 1),
              whichStats: 'click',
              associatedTag: listTags,
              device: isMobile ? 'mobile' : 'desktop',
              numberOfStats: parseInt(sessionStorage.getItem(key) ?? '0') ?? 0,
              userId: userId,
            }),
          );
        }
      } else if (key?.includes('videoOpened=')) {
        if (userId) {
          dispatch(
            await updateTestimonial({
              testimonialId: key.substring(key.indexOf('=') + 1),
              whichStats: 'videoPlayed',
              associatedTag: listTags,
              device: isMobile ? 'mobile' : 'desktop',
              numberOfStats: parseInt(sessionStorage.getItem(key) ?? '0') ?? 0,
              userId: userId,
            }),
          );
        }
      }
    }
    sessionStorage.clear();
  };

  async function handleBeforeUnload() {
    // Vérification si les données existent dans sessionStorage
    await updateStats();
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  // event listener for messages from the embed
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    const rootElement = window.document.getElementById('root');
    if (rootElement) {
      rootElement.style.width = '100%';
    }
  }, []);

  useLayoutEffect(() => {
    window.parent.postMessage(
      {
        name: 'testimonialsLengthEmbedded',
        args: testimonialsList.length,
      },
      '*',
    );
  }, [testimonialsList.length]);

  const [isLoading, setIsLoading] = useState(false);

  const [doneStatus, setDoneStatus] = useState(false);

  useEffect(() => {
    if (data.name == 'setUrlOriginEmbedded') {
      dispatch(
        getPublishedByWebsite({
          origin: data.args.origin.split('?')[0],
          href: data.args.href.split('?')[0],
          hostname: data.args.hostname,
          params: data.args.params,
          hash: data.args.hash,
          device: data.args.device,
        }),
      );
    }
    if (data.name == 'getWidthEmbedded') {
      setDataWidth(data.args);
    }
    if (data.name == 'addedToCart') {
      setTimeout(() => {
        setDoneStatus(true);
        setIsLoading(false);
      }, 300);
    }
  }, [data]);

  useEffect(() => {
    setTestimonialsList(
      testimonials
        .filter((testimonial) => {
          return tags.some((tag) => {
            return (
              testimonial.tags.includes(tag.tag) &&
              tag.displayLocation.showOnEmbedded &&
              testimonial.testimonialType === 'video'
            );
          });
        })
        .sort((a, b) => {
          if (!a.indexPosition || !b.indexPosition || tags.length === 0) {
            return 0;
          }

          for (const tag of tags) {
            const indexA =
              a.indexPosition.find((temp) => temp.tag === tag.tag)?.index || 0;
            const indexB =
              b.indexPosition.find((temp) => temp.tag === tag.tag)?.index || 0;

            if (indexA !== indexB) {
              return indexA - indexB;
            }
          }

          return 0;
        }),
    );
  }, [testimonials, tags]);

  const [refs, setRefs] = useState<RefObject<HTMLDivElement>[]>();

  const [maxSlideRight, setMaxSlideRight] = useState(false);

  const [maxSlideLeft, setMaxSlideLeft] = useState(true);

  const [totalVideoPlayed, setTotalVideoPlayed] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    setRefs(
      [...new Array(testimonialsList.length + 1)].map(() =>
        React.createRef<HTMLDivElement>(),
      ),
    );
  }, [testimonialsList.length]);

  const smoothScroll = (
    element: HTMLElement,
    target: number,
    duration: number,
  ) => {
    const start = element.scrollLeft;
    const distance = target - start;
    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, start, distance, duration);
      element.scrollLeft = run;
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const scrollToElement = (direction: string) => {
    if (refs && containerRef.current) {
      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();

      const scrollFunc = (ref: React.RefObject<HTMLElement>) => {
        if (ref.current) {
          const elementRect = ref.current.getBoundingClientRect();

          if (
            containerRect &&
            ((direction === 'right' &&
              elementRect.right > containerRect.right) ||
              (direction === 'left' &&
                elementRect.left < containerRect.left)) &&
            container
          ) {
            const centeringOffset =
              (containerRect.width - elementRect.width) / 2;

            const scrollAmount =
              direction === 'right'
                ? elementRect.right - containerRect.right + centeringOffset
                : elementRect.left - containerRect.left - centeringOffset;

            if (container) {
              smoothScroll(container, container.scrollLeft + scrollAmount, 500);
            }
            return true;
          }
        }
        return false;
      };

      if (direction === 'right') {
        for (let i = 0; i < refs.length; i++) {
          if (scrollFunc(refs[i])) break;
        }
      } else {
        for (let i = refs.length - 1; i >= 0; i--) {
          if (scrollFunc(refs[i])) break;
        }
      }
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;

      // Nous avons atteint le début du défilement si scrollLeft est 0
      setMaxSlideLeft(scrollLeft === 0);

      // Nous avons atteint la fin du défilement si la somme de scrollLeft et clientWidth est égale à scrollWidth
      setMaxSlideRight(scrollLeft + clientWidth === scrollWidth);
    }
  };

  useEffect(() => {
    window.parent.postMessage(
      { name: 'setTestimonialsSize', args: videoSize },
      '*',
    );
  }, [videoSize]);

  useEffect(() => {
    if (containerRef && testimonialsList.length != 0) {
      containerRef.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef && testimonialsList.length != 0) {
        containerRef.current?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef, refs]);

  const [isMobile, setIsMobile] = useState(false);

  const [dataWidth, setDataWidth] = useState(0);

  const [visibleElements, setVisibleElements] = useState<string[]>([]);

  const visibleElementsRef = useRef<string[]>([]);

  useEffect(() => {
    if (dataWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [dataWidth]);

  const onHoverIncrement = async (testimonial: Testimonial) => {
    if (!isMobile) {
      if (sessionStorage.getItem('hovered=' + testimonial._id) === null) {
        sessionStorage.setItem('hovered=' + testimonial._id, '1');
      } else {
        sessionStorage.setItem(
          'hovered=' + testimonial._id,
          (
            parseInt(
              sessionStorage.getItem('hovered=' + testimonial._id) as string,
            ) + 1
          ).toString(),
        );
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!visibleElementsRef.current.includes(entry.target.id)) {
              visibleElementsRef.current.push(entry.target.id);
              setVisibleElements([...visibleElementsRef.current]);
            }

            if (
              sessionStorage.getItem('videoOpened=' + entry.target.id) === null
            ) {
              sessionStorage.setItem('videoOpened=' + entry.target.id, '1');
            } else {
              sessionStorage.setItem(
                'videoOpened=' + entry.target.id,
                (
                  parseInt(
                    sessionStorage.getItem(
                      'videoOpened=' + entry.target.id,
                    ) as string,
                  ) + 1
                ).toString(),
              );
            }
          } else {
            if (visibleElementsRef.current.includes(entry.target.id)) {
              visibleElementsRef.current = visibleElementsRef.current.filter(
                (id) => id !== entry.target.id,
              );
              setVisibleElements([...visibleElementsRef.current]);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );

    if (refs) {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });
    }

    // Nettoyer lors du démontage du composant
    return () => {
      if (refs) {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      }
    };
  }, [refs]);

  return (
    <div
      className={classes.container}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div className={classes.testimonialContainer} ref={containerRef}>
        {displayNavigation && !maxSlideLeft && (
          <IconButton
            className={`${classes.navButton} left`}
            style={{
              backgroundColor: `${secondaryColor}`,
              color: 'white',
            }}
            disabled={false}
            onClick={() => {
              scrollToElement('left');
            }}
          >
            <ArrowBackIosNew />
          </IconButton>
        )}

        {testimonialsList.map((testimonial, index) => {
          return (
            <div
              id={testimonial._id}
              key={testimonial._id}
              ref={refs ? refs[index] : undefined}
              onMouseEnter={() => {
                onHoverIncrement(testimonial);
              }}
            >
              <EmbeddedPlayer
                testimonial={testimonial}
                mainColor={mainColor}
                size={videoSize}
                outline={videoOutline}
                visibleElements={visibleElements}
                totalVideoPlayed={totalVideoPlayed}
                setTotalVideoPlayed={setTotalVideoPlayed}
                doneStatus={doneStatus}
                setDoneStatus={setDoneStatus}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
          );
        })}

        {displayNavigation && !maxSlideRight && (
          <IconButton
            className={`${classes.navButton} right`}
            style={{
              backgroundColor: `${secondaryColor}`,
              color: 'white',
            }}
            onClick={() => {
              scrollToElement('right');
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default TestimonialsEmbedded;
