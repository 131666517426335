import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { TestimonialStatuses } from '../constants/statuses';
import UserIcon from '../assets/icon-user.svg';
import { theme } from '../constants/theme';

export interface Company {
  _id: string;
  name: string;
  logo?: string;
}

export interface DisplayPreferences {
  showCompanyLogo: boolean;
  showCustomerPicture: boolean;
  showDate: boolean;
  showMoreInfos: boolean;
  showVertical: boolean;
  showSquare: boolean;
}

export interface DisplayProperties {
  clickedNumber: number;
  hoverNumber: number;
  tagAssociated: string;
  onMobile: boolean;
  onDesktop: boolean;
}

export interface IndexPosition {
  index: number;
  tag: string;
}

export interface Testimonial {
  _id: string;
  content: string;
  details?: string;
  displayPreferences: DisplayPreferences;
  createdAt?: string;
  status: TestimonialStatuses;
  tags: string[];
  picture?: string;
  logo?: string;
  information: string;
  callToActionText?: string;
  callToActionUrl?: string;
  linkedPages?: string[];
  indexPosition: IndexPosition[];
  testimonialType?: string;
  displayProperties?: DisplayProperties[];
  statistics: Statistics;
  color?: string;
  title?: string;
  titlePosition?: string;
  titleColor?: string;
  videoImage?: string;
  titleEmbedded?: string;
  titleColorEmbedded?: string;
  titleEmbeddedPosition?: string;
  variantProductId?: string;
}

interface PluginTestimonialResponse {
  testimonials: Testimonial[];
  mainColor?: string;
  secondaryColor?: string;
  enableOnMobile?: boolean;
  tags: {
    tag: string;
    displayLocation: DisplayLocation;
    embeddedSettings: EmbeddedSettings;
  }[];
  position?: string;
  zIndex?: number;
  trigger: Trigger;
  testimonialBubbleRadius?: number;
  testimonialBubbleDimension?: string;
  outlineBubble?: string;
  userId?: string;
  hideKudeoButton?: boolean;
}

export interface Trigger {
  openTestimonialOnDesktopOnLoad: boolean;
  openTestimonialOnMobileOnLoad: boolean;
  timeBeforeOpenOnDesktop: number;
  timeBeforeOpenOnMobile: number;
  openOnScroll: boolean;
  openOnScrollMobile: boolean;
  timeBeforeOpenOnDesktopState: boolean;
  timeBeforeOpenOnMobileState: boolean;
  openOnClick: boolean;
}

export interface DisplayLocation {
  showOnPlugin: boolean;
  showOnEmbedded: boolean;
}

export interface EmbeddedSettings {
  videoSize: string;
  videoOutline: string;
}

interface PluginParamsDto {
  origin: string;
  href: string;
  hostname: string;
  params: string;
  hash: string;
  device: string;
}

interface UpdateStatsDto {
  testimonialId: string;
  whichStats?: string;
  associatedTag: string[];
  device: string;
  numberOfStats: number;
  userId: string;
}

interface UpdateNumberVideoPlayed {
  testimonialId: string;
  secondsPlayed: number;
  device: string;
}

export interface Statistics {
  clickedNumber: number;
  hoverNumber: number;
}

export const getPublishedByWebsite = createAsyncThunk(
  'testimonial/published',
  async (pluginParamsDto: PluginParamsDto) => {
    return await apiRequest<PluginTestimonialResponse>(
      'GET',
      `/testimonial/published/`,
      {
        origin: pluginParamsDto.origin,
        href: pluginParamsDto.href,
        hostname: pluginParamsDto.hostname,
        params: pluginParamsDto.params,
        hash: pluginParamsDto.hash,
        device: pluginParamsDto.device,
      },
    );
  },
);

export const updateTestimonial = createAsyncThunk(
  'testimonial/updateTestimonial',
  async (updateStats: UpdateStatsDto) => {
    const result = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/guest/updateStatistics`,
      undefined,
      {
        testimonialId: updateStats.testimonialId,
        whichStats: updateStats.whichStats,
        associatedTag: updateStats.associatedTag,
        device: updateStats.device,
        numberOfStats: updateStats.numberOfStats,
        userId: updateStats.userId,
      },
    );
    return result;
  },
);

export const updateStatsVideoPlayedTestimonial = createAsyncThunk(
  'testimonial/updateNumberVideoPlayed',
  async (updateStats: UpdateNumberVideoPlayed) => {
    const result: Testimonial = await apiRequest<Testimonial>(
      'PATCH',
      `/testimonial/guest/updatePlayedVideoStats`,
      undefined,
      {
        testimonialId: updateStats.testimonialId,
        playedVideo: updateStats.secondsPlayed,
        device: updateStats.device,
      },
    );
    return result;
  },
);

export const testimonialsAdapter = createEntityAdapter<Testimonial>({
  selectId: (testimonial: Testimonial) => testimonial._id,
});

const testimonialsSlice = createSlice({
  name: 'testimonial',
  initialState: {
    enableOnMobile: false,
    mainColor: '',
    secondaryColor: '',
    testimonials: testimonialsAdapter.getInitialState(),
    isEqual: false,
    tags: [
      {
        tag: '',
        displayLocation: { showOnPlugin: true, showOnEmbedded: false },
        embeddedSettings: { videoSize: 'medium', videoOutline: '' },
      },
    ],
    position: 'bottom',
    zIndex: 999,
    trigger: {
      openTestimonialOnDesktopOnLoad: true,
      openTestimonialOnMobileOnLoad: false,
      timeBeforeOpenOnDesktop: 0,
      timeBeforeOpenOnMobile: 0,
      openOnScroll: false,
      openOnScrollMobile: false,
      timeBeforeOpenOnDesktopState: false,
      timeBeforeOpenOnMobileState: false,
      openOnClick: false,
    },
    testimonialBubbleRadius: 50,
    testimonialBubbleDimension: 'small',
    outlineBubble: undefined as string | undefined,
    userId: undefined as string | undefined,
    hideKudeoButton: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublishedByWebsite.fulfilled, (state, { payload }) => {
      // Parsing
      payload.testimonials.forEach((testimonial) => {
        testimonial.picture =
          testimonial.displayPreferences.showCustomerPicture &&
          testimonial.picture
            ? testimonial.picture
            : UserIcon;
      });

      let isEquals = true;

      const set1 = new Set(
        testimonialsAdapter
          .getSelectors()
          .selectAll(state.testimonials)
          .map((obj) => obj._id),
      );
      const set2 = new Set(payload.testimonials.map((obj) => obj._id));

      if (set1.size === set2.size) {
        for (const id of Array.from(set1)) {
          if (!set2.has(id)) {
            isEquals = false;
          }
        }
      } else {
        isEquals = false;
      }

      state.isEqual = isEquals;
      const temporaryTag = payload.tags;
      if (temporaryTag) {
        temporaryTag.map((tag) => {
          if (tag.embeddedSettings) {
            if (tag.embeddedSettings.videoSize === undefined) {
              tag.embeddedSettings.videoSize = 'medium';
            }
            if (!tag.embeddedSettings.videoOutline) {
              tag.embeddedSettings.videoOutline = '';
            }
          } else {
            tag.embeddedSettings = {
              videoSize: 'medium',
              videoOutline: '',
            };
          }
          if (tag.displayLocation === undefined) {
            tag.displayLocation = { showOnPlugin: true, showOnEmbedded: false };
          }
        });
      }

      state.tags = temporaryTag;

      testimonialsAdapter.setAll(state.testimonials, payload.testimonials);
      state.mainColor = payload.mainColor ?? theme.palette.primary.main;
      state.secondaryColor =
        payload.secondaryColor ?? theme.palette.primary.dark;
      state.enableOnMobile = payload.enableOnMobile ?? false;
      state.position = payload.position ?? 'bottom';
      state.zIndex = payload.zIndex ?? 999;
      state.testimonialBubbleRadius = payload.testimonialBubbleRadius ?? 50;
      state.testimonialBubbleDimension =
        payload.testimonialBubbleDimension ?? 'small';
      state.outlineBubble = payload.outlineBubble ?? undefined;
      state.userId = payload.userId ?? undefined;
      state.hideKudeoButton = payload.hideKudeoButton ?? false;
      state.trigger = {
        openTestimonialOnDesktopOnLoad:
          payload.trigger?.openTestimonialOnDesktopOnLoad != undefined
            ? payload.trigger.openTestimonialOnDesktopOnLoad
            : true,
        openTestimonialOnMobileOnLoad:
          payload.trigger?.openTestimonialOnMobileOnLoad != undefined
            ? payload.trigger.openTestimonialOnMobileOnLoad
            : false,
        timeBeforeOpenOnDesktop:
          payload.trigger?.timeBeforeOpenOnDesktop != undefined
            ? payload.trigger.timeBeforeOpenOnDesktop
            : 0,
        timeBeforeOpenOnMobile:
          payload.trigger?.timeBeforeOpenOnMobile != undefined
            ? payload.trigger.timeBeforeOpenOnMobile
            : 0,
        openOnScroll:
          payload.trigger?.openOnScroll != undefined
            ? payload.trigger.openOnScroll
            : false,

        openOnScrollMobile:
          payload.trigger?.openOnScrollMobile != undefined
            ? payload.trigger.openOnScrollMobile
            : false,

        timeBeforeOpenOnDesktopState:
          payload.trigger?.timeBeforeOpenOnDesktopState != undefined
            ? payload.trigger.timeBeforeOpenOnDesktopState
            : false,

        timeBeforeOpenOnMobileState:
          payload.trigger?.timeBeforeOpenOnMobileState != undefined
            ? payload.trigger.timeBeforeOpenOnMobileState
            : false,

        openOnClick:
          payload.trigger?.openOnClick != undefined
            ? payload.trigger.openOnClick
            : false,
      };
    });
  },
});

export default testimonialsSlice.reducer;
